import * as fromAuth from '../auth/store/auth.reducer';
import * as fromCustomer from '../customer/store/reducers/customer.reducer';
import * as fromCustomerPhone from '../customer/store/reducers/customer-phone.reducer';
import * as fromCustomerSubscriptions from '../customer/store/reducers/customer-subscriptions.reducer';
import * as fromCustomerAddress from '../customer/store/reducers/customer-address.reducer';
import * as fromCustomerFamily from '../customer/store/reducers/customer-family.reducer';
import * as fromCustomerEmail from '../customer/store/reducers/customer-email.reducer';
import * as fromMfap from '../services/mfap/store/mfap.reducer';
import * as fromServiceCards from '../service-cards/data-access/store/service-card.reducer';
import * as fromAppSettings from '../app-settings/store/app-settings.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

export interface AppState {
  auth: fromAuth.AuthState;
  appSettings: fromAppSettings.AppSettingsState;
  customer: fromCustomer.State;
  customerEmail: fromCustomerEmail.State;
  customerPhone: fromCustomerPhone.State;
  customerAddress: fromCustomerAddress.State;
  customerFamily: fromCustomerFamily.State;
  customerSubscriptions: fromCustomerSubscriptions.State;
  mfap: fromMfap.State;
  serviceCards: fromServiceCards.State;
  router: RouterReducerState;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  appSettings: fromAppSettings.appSettingsReducer,
  customer: fromCustomer.customerReducer,
  customerEmail: fromCustomerEmail.customerEmailReducer,
  customerAddress: fromCustomerAddress.customerAddressReducer,
  customerPhone: fromCustomerPhone.customerPhoneReducer,
  customerFamily: fromCustomerFamily.customerPhoneReducer,
  customerSubscriptions: fromCustomerSubscriptions.customerSubscriptionReducer,
  mfap: fromMfap.mfapReducer,
  serviceCards: fromServiceCards.serviceCardReducer,
  router: routerReducer,
};
