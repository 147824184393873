import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerFamilyMember } from '../../customer.model';
import { State } from '../reducers/customer.reducer';
import { selectCustomerAddresses, selectCustomerAddressesLoading } from './customer-address.selector';
import { selectCustomerPhones, selectCustomerPhonesLoading } from './customer-phone.selector';
import { selectCustomerFamily } from './customer-family.selector';

// get the `customer` property from the state object
export const getCustomerFeatureState = createFeatureSelector<State>('customer');

// get the current custoemr from the customer state test
export const selectCurrentCustomer = createSelector(getCustomerFeatureState, (state: State) => state.customer);
export const selectCustomerId = createSelector(getCustomerFeatureState, (state: State) => state.customer.customerId);
export const selectIsUpdatingCustomer = createSelector(
  getCustomerFeatureState,
  (state: State) => state.isUpdatingCustomer
);

export const selectIsSaving = createSelector(getCustomerFeatureState, (state: State) => state.isSaving);

export const selectPrimaryCustomerData = createSelector(
  selectCurrentCustomer,
  selectCustomerAddresses,
  selectCustomerPhones,
  selectCustomerFamily,
  (customer, addresses, phones, dependents: CustomerFamilyMember[]) => {
    const primaryPhone = phones.find((phone) => phone.isPrimary);
    const primaryAddress = addresses.find((address) => address.addressType == 'residential');

    return {
      customer: customer,
      address: primaryAddress,
      phone: primaryPhone,
      dependents: dependents,
    };
  }
);

export const selectCustomerLoading = createSelector(getCustomerFeatureState, (state: State) => state.isLoadingCustomer);

export const selectCustomerProfileLoading = createSelector(
  selectCustomerAddressesLoading,
  selectCustomerPhonesLoading,
  selectCustomerLoading,
  (address, phone, customer) => {
    return address || phone || customer;
  }
);

export const selectCustomerPersonalDialogOpen = createSelector(
  getCustomerFeatureState,
  (state: State) => state.isUpdatingCustomer
);
export const selectCustomerPreferences = createSelector(getCustomerFeatureState, (state: State) => state.preferences);
export const selectCustomerPreferencesLoaded = createSelector(
  getCustomerFeatureState,
  (state: State) => state.preferencesLoaded
);

export const selectCustomerErrorMessage = createSelector(getCustomerFeatureState, (state: State) => state.errorMessage);
export const selectCustomerBirthDay = createSelector(
  getCustomerFeatureState,
  (state: State) => state?.customer?.dateOfBirth
);
