import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { startWith, tap } from 'rxjs';
import { ActivityDetectionService } from '../../data-access/activity-detection.service';
import * as fromApp from '../../../../store/app.reducer';
import * as AuthActions from '../../../store/auth.actions';
import { Title } from '@angular/platform-browser';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
@Component({
    selector: 'app-activity-timeout-dialog',
    templateUrl: './activity-timeout-dialog.component.html',
    styleUrls: ['./activity-timeout-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        AsyncPipe,
    ],
})
export class ActivityTimeoutDialogComponent {
  private activityService = inject(ActivityDetectionService);
  private store = inject<Store<fromApp.AppState>>(Store);
  private titleService = inject(Title);


  timeLeft$ = this.activityService.countdown$.pipe(
    startWith(this.activityService.logoutCountdownSeconds),
    tap((secondsLeft: number) => {
      this.titleService.setTitle(`(${secondsLeft}) Inactive - MyKingston`);
      console.log(secondsLeft);
      if (secondsLeft == 1) {
        console.log('logging out');
        this.store.dispatch(AuthActions.logout());
      }
    })
  );
}
