import { ChangeDetectorRef, Component, DestroyRef, inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AddressManualEntryComponent } from '../../../shared/address-manual-entry/address-manual-entry.component';
import * as fromApp from '../../../store/app.reducer';
import { CustomerAddress } from '../../customer.model';
import * as CustomerAddressActions from '../../store/actions/customer-address.actions';
import * as CustomerActions from '../../store/actions/customer.actions';
import {
  selectAddressTypesAvailable,
  selectCustomerAddressErrorMessage,
  selectIsSaving,
} from '../../store/selectors/customer-address.selector';
import { NgClass, AsyncPipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AddressSearchComponent } from '../../../shared/address-search/address-search.component';
import { FormsModule } from '@angular/forms';
import { CustomerAddressDetailComponent } from './customer-address-detail/customer-address-detail.component';
import { MatButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'app-customer-address',
    templateUrl: './customer-address.component.html',
    styleUrls: ['./customer-address.component.scss'],
    standalone: true,
    imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    AddressSearchComponent,
    FormsModule,
    AddressManualEntryComponent,
    CustomerAddressDetailComponent,
    MatButton,
    MatDialogActions,
    MatProgressBar,
    NgClass,
    AsyncPipe
],
})
export class CustomerAddressComponent implements OnInit, OnChanges {
  private dialogRef = inject<MatDialogRef<CustomerAddressComponent>>(MatDialogRef);
  private store = inject<Store<fromApp.AppState>>(Store);
  private cdr = inject(ChangeDetectorRef);
  data = inject(MAT_DIALOG_DATA);

  loading$: Observable<boolean>;
  isSaving$: Observable<boolean>;
  isEditMode = true;
  errorMessage$: Observable<string>;
  unitNumberSelection: string;
  unitNumber = false;

  addressSelected = false;
  manualEntry = false;
  address: CustomerAddress;
  destroyRef = inject(DestroyRef);

  addressTypes = new Set<string>();
  @ViewChild(AddressManualEntryComponent) manualEntryComp: AddressManualEntryComponent;
  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes detected', changes);
  }
  ngOnInit(): void {
    this.errorMessage$ = this.store.pipe(select(selectCustomerAddressErrorMessage));
    this.isSaving$ = this.store.pipe(select(selectIsSaving));

    this.isEditMode = this.data.mode == 'edit' ? true : false;

    if (this.data.address) {
      this.address = { ...this.data.address };
    } else {
      this.address = {
        addressType: 'residential',
        unit: '',
        streetAddress: '',
        formattedAddress: '',
        streetType: '',
        postalCode: '',
        city: '',
        provinceOrTerritory: '',
        region: '',
        country: '',
        addressId: '',
        lat: 0,
        lng: 0,
        isPrimary: false,
        isKingston: false,
      };
    }

    this.store
      .pipe(select(selectAddressTypesAvailable), takeUntilDestroyed(this.destroyRef))
      .subscribe((availableTypes) => {
        for (const [key, value] of Object.entries(availableTypes.allowedTypes)) {
          if (value > 0 || (availableTypes.length == 1 && this.isEditMode)) {
            this.addressTypes.add(key);
          }
        }
        if (this.addressTypes.size == 0) {
          if (this.data.address.addressType) this.addressTypes.add(this.data.address.addressType);
        }
        console.log(this.addressTypes);
      });
  }

  handleUnitNumberSelection(unitNumberSelection: string) {
    this.unitNumberSelection = unitNumberSelection;
  }
  unitNumberInput() {
    this.unitNumber = true;
  }
  onCancel() {
    this.store.dispatch(CustomerActions.closeCustomerDialog());
  }
  toggleForm() {
    this.manualEntry = !this.manualEntry;
  }
  onAddressSelected(address: CustomerAddress) {
    this.address = address;
    console.log('address was selected', this.address);
    this.addressSelected = true;
    this.cdr.detectChanges();
  }

  onBack() {
    console.log('back');
    this.addressSelected = !this.addressSelected;
    // this.cdr.detectChanges();
  }

  onSubmitAddress() {
    console.log(this.address);

    let address: CustomerAddress = null;
    address = { ...this.address, unit: this.address.unit.trim() };
    //this code overwrites the address fields on an edit so that the backend doesn't keep things like lat/lng
    if (this.manualEntry) {
      const requiredFields: string[] = [
        'addressId',
        'streetAddress',
        'unit',
        'city',
        'provinceOrTerritory',
        'country',
        'postalCode',
        'addressType',
      ];
      for (let key in address) {
        //does the key exist in required keys?
        if (!requiredFields.includes(key)) address[key] = null;
      }
    }

    const typesAvailable = [...this.addressTypes];

    console.log('address type selected:', address.addressType);
    if (typesAvailable.length == 1) {
      console.log('address types', typesAvailable, 'is the only address type available. Setting it as the type');
      address.addressType = typesAvailable[0];
    }

    if (!address.addressType) {
      console.log('no address type detected', this.addressTypes, this.addressTypes[0]);
      address.addressType = typesAvailable[0];
    }

    if (this.data.address?.addressId) {
      this.store.dispatch(
        CustomerAddressActions.startUpdateCustomerAddress({
          payload: { ...this.data.address, ...address },
        })
      );
    } else {
      //new address is being added
      this.store.dispatch(
        CustomerAddressActions.startAddNewCustomerAddress({
          payload: address,
        })
      );
    }
    this.dialogRef.disableClose = true;
  }
}
