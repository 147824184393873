import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/customer-phone.reducer';

// get the `customer` property from the state object
export const getCustomerPhoneFeatureState = createFeatureSelector<State>('customerPhone');

// get the current custoemr from the customer state test
export const selectCustomerPhones = createSelector(
  getCustomerPhoneFeatureState,
  (state: State) => state.customerPhones
);
export const selectIsSaving = createSelector(getCustomerPhoneFeatureState, (state: State) => state.isSaving);
export const selectCustomerPhonesLoading = createSelector(
  getCustomerPhoneFeatureState,
  (state: State) => state.isLoadingPhones
);
export const selectCustomerPhonesDialogOpen = createSelector(
  getCustomerPhoneFeatureState,
  (state: State) => state.isAddingNewPhone
);
export const selectCustomerPhoneErrorMessage = createSelector(
  getCustomerPhoneFeatureState,
  (state: State) => state.phoneErrorMessage
);
export const selectHasPrimaryPhone = createSelector(selectCustomerPhones, (phones) => {
  return phones.find((phone) => phone.isPrimary);
});
export const selectCustomerPhonesLoaded = createSelector(getCustomerPhoneFeatureState, (state: State) => state.loaded);
