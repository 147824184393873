<div ngModelGroup="address">
  <mat-form-field color="accent" class="full-width" appearance="outline">
    <mat-label>Street Address</mat-label>
    <input
      matInput
      required
      autocomplete="off"
      data-testid="streetAddress"
      maxlength="100"
      name="streetAddress"
      [(ngModel)]="address.streetAddress"
      />
      <mat-error align="end">Enter your street address </mat-error>
    </mat-form-field>

    <mat-form-field color="accent" class="full-width" appearance="outline">
      <mat-label>Unit</mat-label>
      <input
        type="text"
        matInput
        name="unit"
        [(ngModel)]="address.unit"
        autocomplete="off"
        data-testid="unit"
        maxlength="10"
        />
      </mat-form-field>

      <mat-form-field color="accent" class="full-width" appearance="outline">
        <mat-label>City</mat-label>
        <input
          type="text"
          matInput
          name="city"
          [(ngModel)]="address.city"
          required
          autocomplete="address-level2"
          data-testid="city"
          maxlength="50"
          />
          <mat-error align="end">Enter your city </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" class="full-width" appearance="outline">
          <mat-label>Province</mat-label>
          <input
            type="text"
            matInput
            name="provinceOrTerritory"
            [(ngModel)]="address.provinceOrTerritory"
            required
            autocomplete="address-level1"
            data-testid="province"
            maxlength="50"
            />
            <mat-error align="end">Enter your province </mat-error>
          </mat-form-field>
          <mat-form-field color="accent" class="full-width" appearance="outline">
            <mat-label>Country</mat-label>
            <input
              (ngModelChange)="countryChange($event)"
              type="text"
              matInput
              name="country"
              [(ngModel)]="address.country"
              required
              autocomplete="country"
              data-testid="country"
              maxlength="50"
              />
              <mat-error align="end"> Enter your country </mat-error>
            </mat-form-field>

            <mat-form-field color="accent" class="full-width" appearance="outline">
              <mat-label>Postal code</mat-label>
              <input
                type="text"
                matInput
                name="postalCode"
                [(ngModel)]="address.postalCode"
                required
                autocomplete="postal-code"
                [mask]="postalMask"
                [placeholder]="postalPlaceholder"
                maxLength="7"
                min="5"
                data-testid="postalCode"
                />
                <mat-error align="end"> Enter your postalCode </mat-error>
              </mat-form-field>
              @if (addressTypes.size > 1) {
                <div>
                  <label id="address-type-radio-group-label">Select your address type</label>
                  <mat-radio-group
                    required
                    aria-labelledby="address-type-radio-group-label"
                    name="addressTypeManual"
                    class="address-type-radio-group"
                    [(ngModel)]="address.addressType"
                    >
                    @for (type of addressTypes; track type) {
                      <mat-radio-button [value]="type" [attr.data-testid]="'address-' + type">
                        {{ type | titlecase }}
                      </mat-radio-button>
                    }
                  </mat-radio-group>
                </div>
              } @else {
                <div>
                  <p>
                    This address will be saved as a
                    {{ addressTypes.has('mailing') ? 'Mailing' : 'Residential' }} address
                  </p>
                </div>
              }
            </div>
