import { createReducer, on } from '@ngrx/store';
import * as CustomerSubscriptionsActions from '../actions/customer-subscriptions.actions';
import { CustomerSubscriptions } from '../../customer.model';

export interface State {
  subscriptions: CustomerSubscriptions | null;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  subscriptions: null,
  loading: false,
  error: null,
};

export const customerSubscriptionReducer = createReducer(
  initialState,

  on(CustomerSubscriptionsActions.loadCustomerSubscriptions, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(CustomerSubscriptionsActions.loadCustomerSubscriptionsSuccess, (state, { subscriptions }) => ({
    ...state,
    subscriptions,
    loading: false,
    error: null,
  })),

  on(CustomerSubscriptionsActions.loadCustomerSubscriptionsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(CustomerSubscriptionsActions.updateCustomerSubscription, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(CustomerSubscriptionsActions.updateCustomerSubscriptionSuccess, (state, { subscriptions }) => ({
    ...state,
    subscriptions,
    loading: false,
    error: null,
  })),

  on(CustomerSubscriptionsActions.updateCustomerSubscriptionFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
