import { Action, createReducer, on } from '@ngrx/store';
import { CustomerFamilyMember } from '../../customer.model';
import * as CustomerFamilyActions from '../actions/customer-family.actions';

export interface State {
  customerFamily: CustomerFamilyMember[];
  customerFamilyTemp: CustomerFamilyMember[];
  familyErrorMessage: string;
  loading: boolean;
  isSaving: boolean;
  isAddingNewMember: boolean;
  loaded: boolean;
}

const initialState: State = {
  customerFamily: [],
  customerFamilyTemp: [],
  familyErrorMessage: null,
  loading: false,
  isSaving: false,
  isAddingNewMember: false,
  loaded: false,
};

const _customerPhoneReducer = createReducer(
  initialState,
  on(CustomerFamilyActions.fetchCustomerFamily, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(CustomerFamilyActions.setCustomerFamily, (state, action) => ({
    ...state,
    customerFamily: action.payload,
    loading: false,
    isSaving: false,
    isAddingNewMember: false,
    loaded: true,
  })),
  on(CustomerFamilyActions.customerFamilyFailed, (state, action) => ({
    ...state,
    familyErrorMessage: action.payload,
    loading: false,
    isSaving: false,
    loaded: false,
  })),
  on(CustomerFamilyActions.addNewCustomerFamilyMember, (state, action) => ({
    ...state,
    customerFamilyTemp: action.payload,
    isAddingNewMember: true,
    isSaving: true,
  })),
  on(CustomerFamilyActions.deleteCustomerFamilyMember, (state, action) => ({
    ...state,
    isAddingNewMember: false,
    isSaving: true,
    customerFamilyTemp: state.customerFamily.filter((_member, index, customerPhones) => {
      return customerPhones[index].customerId !== action.payload;
    }),
  })),

  on(CustomerFamilyActions.updateCustomerFamilyMember, (state, action) => {
    const updatedMember: CustomerFamilyMember = {
      ...action.payload,
    };
    const updatedMembers = [...state.customerFamily];
    let index = updatedMembers.findIndex((x) => x.customerId === action.payload.customerId);
    updatedMembers[index] = updatedMember;

    return {
      ...state,
      customerFamilyTemp: updatedMembers,
      isAddingNewMember: false,
      isSaving: true,
    };
  }),

  on(CustomerFamilyActions.displayCustomerFamilyDialog, (state) => ({
    ...state,
    isAddingNewMember: true,
    isSaving: false,
  })),
  on(CustomerFamilyActions.cancelNewCustomerFamily, (state) => ({
    ...state,
    familyErrorMessage: null,
    isAddingNewMember: false,
  })),
  on(CustomerFamilyActions.closeError, (state) => ({
    ...state,
    familyErrorMessage: null,
  }))
);
export function customerPhoneReducer(state: State, action: Action) {
  return _customerPhoneReducer(state, action);
}
