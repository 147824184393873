import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { IConfig, NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { AddressManualEntryComponent } from './address-manual-entry/address-manual-entry.component';
import { AddressSearchComponent } from './address-search/address-search.component';
import { BackBarComponent } from './back-bar/back-bar.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { DialogNoticeComponent } from './dialog-notice/dialog-notice.component';
import { MessageDisplayComponent } from './message-display/message-display.component';
import { LabelDataFlexComponent } from './label-data-flex/label-data-flex.component';

import { UiEmailVerifyComponent } from './ui-email-verify/ui-email-verify.component';
import { CreateRegisterAccountComponent } from './create-register-account/create-register-account.component';
import { AddResidentialAddressComponent } from './add-residential-address/add-residential-address.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { ConsentToUseInfoComponent } from './consent-to-use-info/consent-to-use-info.component';
import { AddressSearchInputComponent } from './address-search/address-search-input/address-search-input.component';
import { SafePipe } from './safe.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    NgxSkeletonLoaderModule,
    CommonModule,
    GoogleMapsModule,
    CurrencyMaskModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    RouterModule,
    NgxMaskDirective,
    AddressManualEntryComponent,
    AddressSearchComponent,
    BackBarComponent,
    DeleteDialogComponent,
    DialogNoticeComponent,
    MessageDisplayComponent,
    LabelDataFlexComponent,
    UiEmailVerifyComponent,
    CreateRegisterAccountComponent,
    AddResidentialAddressComponent,
    TermsOfServiceComponent,
    ConsentToUseInfoComponent,
    AddressSearchInputComponent,
    SafePipe,
  ],
  providers: [provideNgxMask()],
  exports: [
    AddressManualEntryComponent,
    AddressSearchComponent,
    CommonModule,
    CurrencyMaskModule,
    MessageDisplayComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiEmailVerifyComponent,
    LabelDataFlexComponent,
    CreateRegisterAccountComponent,
    AddResidentialAddressComponent,
    TermsOfServiceComponent,
    ConsentToUseInfoComponent,
    SafePipe,
    BackBarComponent,
    NgxSkeletonLoaderModule,
  ],
})
export class SharedModule {}
