import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/customer-address.reducer';

// get the `customer` property from the state object
export const getCustomerAddressFeatureState = createFeatureSelector<State>('customerAddress');

// get the current custoemr from the customer state test
export const selectCustomerAddresses = createSelector(
  getCustomerAddressFeatureState,
  (state: State) => state.customerAddresses
);
export const selectIsSaving = createSelector(getCustomerAddressFeatureState, (state: State) => state.isSaving);
export const selectCustomerAddressesLoading = createSelector(
  getCustomerAddressFeatureState,
  (state: State) => state.isLoadingAddresses
);
export const selectCustomerAddressesDialogOpen = createSelector(
  getCustomerAddressFeatureState,
  (state: State) => state.isAddingNewAddress
);
export const selectCustomerAddressErrorMessage = createSelector(
  getCustomerAddressFeatureState,
  (state: State) => state.addressErrorMessage
);
export const selectAddressTypesAvailable = createSelector(selectCustomerAddresses, (addresses) => {
  let allowedTypes = { residential: 1, mailing: 1 };

  addresses.forEach((address) => {
    allowedTypes[address.addressType] -= 1;
  });
  return { allowedTypes, length: addresses.length };
});
export const selectHasPrimaryAddress = createSelector(selectCustomerAddresses, (addresses) => {
  return addresses.find((address) => address.addressType === 'residential');
});
export const selectAddressIsKingston = createSelector(selectHasPrimaryAddress, (address) => {
  return address?.isKingston || false;
});

export const selectCustomerAddressLoaded = createSelector(
  getCustomerAddressFeatureState,
  (state: State) => state.loaded
);
