export const AuthConfig = Object({
  prod: {
    domain: 'login.mykingston.ca',
    clientId: 'pThEMnRpY8IV6fj46rw3h8ws81adD3Cb',
    audience: 'https://auth0-jwt-authorizer',
    serverUrl: 'https://api.mykingston.ca/v1',
    serverWebSocketUrl: 'wss://ws.mykingston.ca/v1',
    scope: 'read:current_user',
    osvc_clientId: 'TBCW9BxsaApG2tlJxamx9RuJVHL9HloQ',
  },
  staging: {
    domain: 'test-login.mykingston.ca',
    clientId: '9jBYcl6X9KjdCx9Pk85Ksnxo45NK54S8',
    audience: 'https://auth0-jwt-authorizer',
    serverUrl: 'https://test-api.mykingston.ca/v1',
    serverWebSocketUrl: 'wss://test-ws.mykingston.ca/v1',
    scope: 'read:current_user',
    osvc_clientId: '7Atz59FyVPJOlHp8DByS9CXKV1M3686p',
  },
  dev: {
    domain: 'dev-login.mykingston.ca',
    clientId: 'HoOov5aFRTlNCkY8mao8b6pF06qiJeIx',
    audience: 'https://auth0-jwt-authorizer',
    serverUrl: 'https://dev-api.mykingston.ca/v1',
    serverWebSocketUrl: 'wss://dev-ws.mykingston.ca/v1',
    scope: 'read:current_user',
    osvc_clientId: '7zrUeO1C3WIruI6bKD50BmcacTpMANRJ',
  },
});
