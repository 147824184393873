import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../auth/store/auth.actions';
import * as fromApp from '../../store/app.reducer';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-create-register-account',
    templateUrl: './create-register-account.component.html',
    styleUrls: ['./create-register-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButton],
})
export class CreateRegisterAccountComponent {
  private store = inject<Store<fromApp.AppState>>(Store);


  onLogin() {
    this.store.dispatch(AuthActions.login());
  }
  onCreate() {
    this.store.dispatch(AuthActions.createAccount());
  }
}
