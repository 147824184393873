import { createAction, props } from '@ngrx/store';
import { MfapApplication } from '../mfap.model';

export const fetchMfapApplication = createAction('[MFAP] Fetch MFAP Application');

export const startCreateMfapApplication = createAction(
  '[MFAP] Start Create MFAP Application',
  props<{ payload: MfapApplication }>()
);

export const startRenewMfapApplication = createAction(
  '[MFAP] Renew MFAP Application',
  props<{ payload: MfapApplication }>()
);

export const renewMfapApplicationSuccess = createAction(
  '[MFAP] Renew MFAP Application Success',
  props<{ payload: MfapApplication }>()
);

export const createMfapApplicationSuccess = createAction(
  '[MFAP] Create MFAP Application Success',
  props<{ payload: MfapApplication }>()
);

export const setMfapApplication = createAction('[MFAP] Set MFAP Application', props<{ payload: MfapApplication }>());

export const mfapApplicationFailed = createAction('[MFAP] MFAP Application Failed', props<{ payload: string }>());
export const mfapEhbFailed = createAction('[MFAP] MFAP EHB Failed', props<{ payload: string }>());
export const mfapPvFailed = createAction('[MFAP] MFAP PV Failed', props<{ payload: string }>());
export const mfapSparkFailed = createAction('[MFAP] MFAP SPARK Failed', props<{ payload: string }>());
export const mfapNoApplication = createAction('[MFAP] No application found');

export const startDeleteMfapApplication = createAction('[MFAP] Start Delete MFAP Application');

export const deleteMfapApplicationSuccess = createAction('[MFAP] Delete MFAP Application Success');

export const hideMfapResultComponent = createAction('[MFAP] Hide result component');
export const clearMfapError = createAction('[MFAP] Clear error');

export const startMfapEhbApplication = createAction('[MFAP] Start MFAP EHB Application');
export const startMfapPvApplication = createAction('[MFAP] Start MFAP PV Application');
export const startMfapSparkApplication = createAction('[MFAP] Start MFAP SPARK Application');

export const successMfapEhbApplication = createAction(
  '[MFAP] MFAP EHB Application Success',
  props<{ payload: MfapApplication }>()
);
export const successMfapPvApplication = createAction(
  '[MFAP] MFAP Pv Application Success',
  props<{ payload: MfapApplication }>()
);
export const successMfapSparkApplication = createAction(
  '[MFAP] MFAP SPARK Application Success',
  props<{ payload: MfapApplication }>()
);

export const startMfapEhbNewLetter = createAction('[MFAP] Start MFAP EHB New Letter');

export const successMfapEhbNewLetter = createAction(
  '[MFAP] MFAP EHB New Letter Success',
  props<{ payload: MfapApplication }>()
);
