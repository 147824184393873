import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppSettingsState } from './app-settings.reducer';

export const getAppSettingsFeatureState = createFeatureSelector<AppSettingsState>('appSettings');

export const selectAppSettings = createSelector(getAppSettingsFeatureState, (state: AppSettingsState) => {
  return state.settings;
});

export const selectAppSettingsError = createSelector(getAppSettingsFeatureState, (state: AppSettingsState) => {
  return state.error;
});
