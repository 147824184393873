import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarLabel, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: 'snack-bar.component.html',
  styleUrl: 'snack-bar.component.scss',
  standalone: true,
  imports: [MatButtonModule, MatSnackBarLabel],
})
export class SnackBarComponent {
  snackBarRef = inject(MatSnackBarRef);

  data: { message: string; action: string } = inject(MAT_SNACK_BAR_DATA);
}
