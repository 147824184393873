import { createReducer, on, Action } from '@ngrx/store';
import { MfapApplication } from '../mfap.model';

import * as MfapActions from './mfap.actions';

export interface State {
  mfapApplication: MfapApplication; //user's mfap application
  loading: boolean; //are the mfap applications loading?
  ehb_loading: boolean;
  pv_loading: boolean;
  spark_loading: boolean;
  error: string | null; //HTTP error message to display
  submitted: boolean; //has the form been successfully submitted?
  saving: boolean; //is an attempt being made to save the application? HTTP POST
  //showResultComponent: boolean;
}

export const initialState: State = {
  mfapApplication: null,
  loading: false,
  ehb_loading: false,
  pv_loading: false,
  spark_loading: false,
  error: null,
  submitted: false,
  saving: false,
  //showResultComponent: true,
};

const _mfapReducer = createReducer(
  initialState,
  on(MfapActions.fetchMfapApplication, (state) => ({
    ...state,
    loading: true,
    error: null,
    submitted: false,
  })),
  on(MfapActions.startCreateMfapApplication, (state) => ({
    ...state,
    saving: true,
  })),
  on(MfapActions.startRenewMfapApplication, (state) => ({
    ...state,
    saving: true,
  })),
  on(MfapActions.createMfapApplicationSuccess, (state, action) => ({
    ...state,
    mfapApplication: action.payload,
    error: null,
    submitted: true,
    saving: false,
    // showResultComponent: true,
  })),
  on(MfapActions.renewMfapApplicationSuccess, (state, action) => ({
    ...state,
    mfapApplication: action.payload,
    error: null,
    submitted: true,
    saving: false,
    // showResultComponent: true,
  })),
  on(MfapActions.mfapApplicationFailed, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
    submitted: false,
    saving: false,
    //showResultComponent: true,
  })),

  on(MfapActions.mfapNoApplication, (state) => ({
    ...state,
    loading: false,
    mfapApplication: null,
  })),

  on(MfapActions.startMfapEhbApplication, MfapActions.startMfapEhbNewLetter, (state) => ({
    ...state,
    ehb_loading: true,
  })),
  on(MfapActions.startMfapPvApplication, (state) => ({
    ...state,
    pv_loading: true,
  })),
  on(MfapActions.startMfapSparkApplication, (state) => ({
    ...state,
    spark_loading: true,
  })),
  on(MfapActions.successMfapEhbApplication, MfapActions.successMfapEhbNewLetter, (state, action) => ({
    ...state,
    mfapApplication: action.payload,
    ehb_loading: false,
  })),
  on(MfapActions.successMfapPvApplication, (state, action) => ({
    ...state,
    mfapApplication: action.payload,
    pv_loading: false,
  })),
  on(MfapActions.successMfapSparkApplication, (state, action) => ({
    ...state,
    mfapApplication: action.payload,
    spark_loading: false,
  })),
  on(MfapActions.mfapEhbFailed, (state) => ({
    ...state,
    ehb_loading: false,
  })),
  on(MfapActions.mfapPvFailed, (state) => ({
    ...state,
    pv_loading: false,
  })),
  on(MfapActions.mfapSparkFailed, (state) => ({
    ...state,
    spark_loading: false,
  })),

  on(MfapActions.setMfapApplication, (state, action) => ({
    ...state,
    mfapApplication: { ...action.payload },
    loading: false,
  })),
  // on(MfapActions.hideMfapResultComponent, (state) => ({
  //   ...state,
  //   showResultComponent: false,
  // })),
  on(MfapActions.clearMfapError, (state) => ({
    ...state,
    error: null,
  })),
  on(MfapActions.deleteMfapApplicationSuccess, (state) => ({
    ...state,
    error: null,
    mfapApplication: null,
  }))
);
export function mfapReducer(state: State, action: Action) {
  return _mfapReducer(state, action);
}
