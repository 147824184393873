import { createAction, props } from '@ngrx/store';
import { CustomerAddress } from '../../customer.model';

export const fetchCustomerAddresses = createAction('[Customer Address] Fetch Customer Addresses');

export const setCustomerAddresses = createAction(
  '[Customer Address] Set Customer Addresses',
  props<{ payload: CustomerAddress[] }>()
);
export const displayCustomerAddressDialog = createAction('[Customer Address] Display Customer Address Dialog');

export const startAddNewCustomerAddress = createAction(
  '[Customer Address] Start Add New Customer Address',
  props<{ payload: CustomerAddress }>()
);

export const addCustomerAddressSuccess = createAction(
  '[Customer Address] Add Customer Address Success',
  props<{ payload: CustomerAddress }>()
);

export const startUpdateCustomerAddress = createAction(
  '[Customer Address] Update Customer Address START',
  props<{ payload: CustomerAddress }>()
);

export const updateCustomerAddressSuccess = createAction(
  '[Customer Address] Update Customer Address SUCCESS',
  props<{
    payload: CustomerAddress;
  }>()
);

export const deleteCustomerAddressSuccess = createAction('[Customer Address] Delete Customer Address Success');

export const startDeleteCustomerAddress = createAction(
  '[Customer Address] Starting Customer Address Delete',
  props<{ payload: string }>()
);

export const customerAddressFailed = createAction(
  '[Customer Address] Customer Address Failed',
  props<{ payload: string }>()
);

export const closeError = createAction('[Customer Address] Close Address Error');
