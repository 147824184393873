import { createAction, props } from '@ngrx/store';
import { ServiceCard } from '../service-card.model';

export const fetchAllServiceCards = createAction('[Service Cards] Fetch All');
export const fetchServiceCardsByCustomer = createAction('[Service Cards] Fetch By Customer');

export const ServiceCardsLoaded = createAction(
  '[Service Cards] Service Cards Loaded',
  props<{ serviceCards: ServiceCard[] }>()
);
export const ServiceCardsFailure = createAction(
  '[Service Cards] Service Cards Failure',
  props<{ errorMessage: string }>()
);
