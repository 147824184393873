import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of, timer } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { ActivityDetectionService } from '../activity-detection/data-access/activity-detection.service';
import { AuthenticationService } from '../auth.service';
import * as AuthActions from './auth.actions';

const handleError = (errorRes: any) => {
  let errorMessage = errorRes?.error?.message || 'An unknown error occurred!';

  return of(
    AuthActions.authFailed({
      payload: errorMessage,
    })
  );
};

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private authService = inject(AuthenticationService);
  private activityService = inject(ActivityDetectionService);

  //add extend timer for every action detected.

  extendAuthTimeout$ = createEffect(() => {
    console.log('timeout effect');
    return this.actions$.pipe(
      ofType(AuthActions.extendLogoutTimer, AuthActions.loginComplete),
      switchMap(() => timer(env.auth.clientTimeoutMilliseconds)),
      map(() => AuthActions.inactivityDetected())
    );
  });

  inactivityDetected$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.inactivityDetected),
        tap(() => {
          this.activityService.showInactivityWarning();
          //return AuthActions.inactivityDetected();
        })
      ),
    { dispatch: false }
  );
  //Check Auth effect
  checkAuth$ = createEffect(() =>
    this.actions$.pipe(
      // If an action with the type 'checkAuth' occurs in the actions$ stream...
      ofType(AuthActions.checkAuth),
      // return an observable including the latest info from 'isLoggedIn' and 'userProfile'
      switchMap(() =>
        combineLatest([this.authService.isLoggedIn$, this.authService.user$])
          // take the first instance of the emitted observable, as the emittance happens multiple times with the same state.
          .pipe(take(1))
      ),
      // Take it out and return the appropriate action based on if logged in or not
      switchMap(([isLoggedIn, profile]) => {
        if (isLoggedIn) {
          console.log('profile is', profile);

          return this.authService.accessToken$.pipe(
            switchMap((token) => {
              if (token) {
                return of(AuthActions.loginComplete({ profile, isLoggedIn, token }));
              }
              return of({ type: 'DUMMY STATE: Could not get token' });
            })
          );
        } else {
          return of(AuthActions.authFailed({ payload: 'user not logged in' }));
        }
      })
    )
  );

  //Login Effect
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.login),
        tap(() => {
          this.authService.login();
        })
      ),
    { dispatch: false }
  );
  //Login Effect
  createAccount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.createAccount),
        tap(() => {
          this.authService.createAccount();
        })
      ),
    { dispatch: false }
  );

  //Logout Effect
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() => {
        this.authService.logout();
      }),
      switchMap(() => of(AuthActions.logoutComplete()))
    )
  );

  changePasswordEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.sendPasswordChangeEmail),
      switchMap(() => {
        return this.authService.sendPasswordChangeEmail().pipe(
          map(() => {
            return AuthActions.passwordChangeEmailSent();
          }),
          catchError((errResponse) => {
            return handleError(errResponse);
          })
        );
      })
    )
  );
}
