import { createAction, props } from '@ngrx/store';
import { CustomerPhone } from '../../customer.model';

export const setCustomerPhones = createAction(
  '[Customer Phone] Set Customer Phones',
  props<{ payload: CustomerPhone[] }>()
);

export const displayCustomerPhoneDialog = createAction('[Customer Phone] Display Customer Phone Dialog');

export const cancelNewCustomerPhone = createAction('[Customer Phone] Hide New Customer Phone Dialog');

export const startAddNewCustomerPhone = createAction(
  '[Customer Phone] Start Add New Customer Phone',
  props<{ payload: CustomerPhone }>()
);

export const addCustomerPhoneSuccess = createAction(
  '[Customer Phone] Add Customer Phone Success',
  props<{ payload: CustomerPhone }>()
);

export const fetchCustomerPhones = createAction('[Customer Phone] Fetch Customer Phones');

export const startUpdateCustomerPhone = createAction(
  '[Customer Phone] Update Customer Phone START',
  props<{ payload: CustomerPhone }>()
);

export const updateCustomerPhoneSuccess = createAction(
  '[Customer Phone] Update Customer Phone SUCCESS',
  props<{
    payload: CustomerPhone;
  }>()
);

export const deleteCustomerPhoneSuccess = createAction('[Customer Phone] Delete Customer Phone Success');

export const startDeleteCustomerPhone = createAction(
  '[Customer Phone] Starting Customer Phone Delete',
  props<{ payload: string }>()
);

export const customerPhoneFailed = createAction('[Customer Phone] Customer Phone Failed', props<{ payload: string }>());
export const closeError = createAction('[Customer Phone] Close Phone Error');
