import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FirePermitRequestStore } from '../../data-access/fire-permit.store';

import { SharedModule } from 'src/app/shared/shared.module';

import { Observable, map } from 'rxjs';
import { FireBanZone } from '../../data-access/fire-permit.model';

@Component({
  selector: 'app-fire-ban-status',
  templateUrl: './fire-ban-status.component.html',
  styleUrls: ['./fire-ban-status.component.scss'],
  providers: [FirePermitRequestStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule],
})
export class FireBanStatusComponent implements OnInit {
  private firePermitRequestStore = inject(FirePermitRequestStore);

  zone1Status: string;
  zone2Status: string;
  destroyRef = inject(DestroyRef);

  loading$ = this.firePermitRequestStore.statusLoading$;
  error$ = this.firePermitRequestStore.statusError$;
  statusData$: Observable<{ zone1Status: string; zone2Status: string }>;

  ngOnInit(): void {
    this.firePermitRequestStore.loadFireBanStatus();

    this.statusData$ = this.firePermitRequestStore.statusData$.pipe(
      map((statusData: FireBanZone[]) => ({
        zone1Status: this.mapStatus(statusData?.find((zone) => zone.zoneId === 1)?.status),
        zone2Status: this.mapStatus(statusData?.find((zone) => zone.zoneId === 2)?.status),
      }))
    );
  }
  mapStatus(status: string): string {
    switch (status) {
      case 'No':
        return 'No ban';
      case 'Partial':
        return 'Partial ban';
      case 'Total':
        return 'Total ban';
      default:
        return 'Unknown';
    }
  }

  getStatusChipClass(status: string): string {
    switch (status) {
      case 'No ban':
        return 'success';
      case 'Partial ban':
        return 'accent';
      case 'Total ban':
        return 'warn';
      default:
        return '';
    }
  }
}
