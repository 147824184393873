import { Action, createReducer, on } from '@ngrx/store';
import { CustomerEmail } from '../../customer.model';
import * as CustomerEmailActions from '../actions/customer-email.actions';
export interface State {
  emails: CustomerEmail[];
  waitingToVerify: boolean;
  emailToVerifyId: string;
  emailVerifiedSuccess: boolean;
  emailError: string;
  loading: boolean;
  primaryEmailUpdated: boolean;
  loaded: boolean;
}

const initialState: State = {
  emails: [],
  waitingToVerify: false,
  emailToVerifyId: null,
  emailVerifiedSuccess: false,
  emailError: null,
  loading: false,
  primaryEmailUpdated: false,
  loaded: false,
};

const _customerEmailReducer = createReducer(
  initialState,

  on(CustomerEmailActions.setCustomerEmails, (state, action) => ({
    ...state,
    emails: action.payload,
    emailError: null,
    loaded: true,
  })),
  on(CustomerEmailActions.fetchCustomerEmails, (state) => ({
    ...state,
    emailError: null,
    loaded: false,
  })),

  on(CustomerEmailActions.addNewCustomerEmail, (state) => ({
    ...state,
    loading: true,
  })),

  on(CustomerEmailActions.addNewCustomerEmailSuccess, (state, action) => ({
    ...state,
    emails: [...state.emails, action.payload],
    waitingToVerify: false,
    loading: true,
  })),
  on(CustomerEmailActions.sendCustomerEmailVerification, (state, action) => ({
    ...state,
    waitingToVerify: true,
    emailToVerifyId: action.payload.emailId,
  })),

  on(CustomerEmailActions.updateCustomerEmailVerification, (state) => ({
    ...state,
    loading: true,
  })),
  on(CustomerEmailActions.updateCustomerEmailVerificationSuccess, (state, action) => {
    return {
      ...state,
      waitingToVerify: false,
      emailToVerifyId: null,
      emailVerifiedSuccess: true,
      loading: false,
      emails: state.emails.map((email) => {
        if (email.emailId !== action.payload.emailId) return email;

        return {
          ...email,
          isVerified: true,
        };
      }),
    };
  }),
  on(CustomerEmailActions.updateCustomerEmailPrimary, (state) => ({
    ...state,
    loading: true,
  })),
  on(CustomerEmailActions.updateCustomerEmailPrimarySuccess, (state) => ({
    ...state,
    waitingToVerify: false,
    emailToVerifyId: null,
    emailVerifiedSuccess: true,
    loading: false,
    primaryEmailUpdated: true,
    //emails: [...state.emails, action.payload],
  })),
  on(CustomerEmailActions.updateCustomerEmailPrimaryFailed, (state) => ({
    ...state,
    loading: false,
    emailError: 'Could not make primary',
  })),
  on(CustomerEmailActions.resendCustomerEmailVerification, (state) => ({
    ...state,
    loading: true,
  })),
  on(CustomerEmailActions.resendCustomerEmailVerificationSuccess, (state) => ({
    ...state,
    waitingToVerify: false,
    emailToVerifyId: null,
    //emails: [...state.emails, action.payload],
    loading: false,
  })),
  on(CustomerEmailActions.deleteCustomerEmail, (state) => ({
    ...state,
    loading: true,
  })),
  on(CustomerEmailActions.deleteCustomerEmailSuccess, (state, action) => ({
    ...state,
    waitingToVerify: false,
    emailToVerifyId: null,
    emailVerifiedSuccess: false,
    loading: false,
    emails: state.emails.filter((_email, index, customerEmails) => {
      return customerEmails[index].emailId !== action.payload;
    }),
  })),
  on(CustomerEmailActions.routeCustomerToVerifyEmail, (state) => ({
    ...state,
    waitingToVerify: false,
    loading: false,
  })),

  on(CustomerEmailActions.clearCustomerProfileErrorMessage, (state) => ({
    ...state,
    emailError: null,
  })),
  on(CustomerEmailActions.updateCustomerEmailVerificationFailed, (state) => ({
    ...state,
    loading: false,
    emailError: 'Could not verify',
  })),

  on(CustomerEmailActions.customerEmailsFailed, (state, action) => ({
    ...state,
    emailError: action.payload,
    waitingToVerify: false,
    emailToVerifyId: null,
    emailVerifiedSuccess: false,
    loading: false,
    loaded: false,
  }))
);

export function customerEmailReducer(state: State, action: Action) {
  return _customerEmailReducer(state, action);
}
