import { Action, createReducer, on } from '@ngrx/store';
import { CustomerPhone } from '../../customer.model';
import * as CustomerPhoneActions from '../actions/customer-phone.actions';

export interface State {
  customerPhones: CustomerPhone[];
  isLoadingPhones: boolean;
  phoneId: string;
  isAddingNewPhone: boolean;
  phoneErrorMessage: string;
  isSaving: boolean;
  loaded: boolean;
}

const initialState: State = {
  customerPhones: [],
  isLoadingPhones: false,
  phoneId: null,
  isAddingNewPhone: false,
  phoneErrorMessage: null,
  isSaving: false,
  loaded: false,
};

const _customerPhoneReducer = createReducer(
  initialState,
  on(CustomerPhoneActions.fetchCustomerPhones, (state) => ({
    ...state,
    isLoadingPhones: true,
    isAddingNewPhone: false,
    loaded: false,
  })),
  on(CustomerPhoneActions.displayCustomerPhoneDialog, (state) => ({
    ...state,
    isAddingNewPhone: true,
    isSaving: false,
  })),
  on(CustomerPhoneActions.cancelNewCustomerPhone, (state) => ({
    ...state,
    phoneErrorMessage: null,
    isAddingNewPhone: false,
  })),
  on(CustomerPhoneActions.customerPhoneFailed, (state, action) => ({
    ...state,
    phoneErrorMessage: action.payload,
    isLoadingPhones: false,
    isSaving: false,
    loaded: false,
  })),
  on(CustomerPhoneActions.startDeleteCustomerPhone, (state, action) => ({
    ...state,
    phoneId: action.payload,
    isSaving: true,
  })),
  on(CustomerPhoneActions.deleteCustomerPhoneSuccess, (state) => ({
    ...state,
    isAddingNewPhone: false,
    isSaving: false,
    customerPhones: state.customerPhones.filter((_phone, index, customerPhones) => {
      return customerPhones[index].phoneId !== state.phoneId;
    }),
  })),
  on(CustomerPhoneActions.startUpdateCustomerPhone, (state) => ({
    ...state,
    isSaving: true,
  })),
  on(CustomerPhoneActions.updateCustomerPhoneSuccess, (state, action) => {
    const updatedPhone: CustomerPhone = {
      ...action.payload,
    };
    const updatedPhones = [...state.customerPhones];
    let index = updatedPhones.findIndex((x) => x.phoneId === action.payload.phoneId);
    updatedPhones[index] = updatedPhone;

    return {
      ...state,
      customerPhones: updatedPhones,
      isAddingNewPhone: false,
      isSaving: false,
      unsavedPhone: false,
    };
  }),
  on(CustomerPhoneActions.startAddNewCustomerPhone, (state, action) => ({
    ...state,
    newPhone: action.payload,
    isSaving: true,
  })),
  on(CustomerPhoneActions.setCustomerPhones, (state, action) => ({
    ...state,
    customerPhones: [
      ...action.payload.slice().sort((a, b) => {
        //sort the phones so that primary is at the top of the list
        return a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1;
      }),
    ],
    isLoadingPhones: false,
    loaded: true,
  })),
  on(CustomerPhoneActions.addCustomerPhoneSuccess, (state, action) => ({
    ...state,
    customerPhones: [...state.customerPhones, action.payload],
    isAddingNewPhone: false,
    isSaving: false,
  })),
  on(CustomerPhoneActions.closeError, (state) => ({
    ...state,
    phoneErrorMessage: null,
  }))
);
export function customerPhoneReducer(state: State, action: Action) {
  return _customerPhoneReducer(state, action);
}
