import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CreateRegisterAccountComponent } from '../../../shared/create-register-account/create-register-account.component';

@Component({
    selector: 'app-home-banner',
    templateUrl: './home-banner.component.html',
    styleUrls: ['./home-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CreateRegisterAccountComponent],
})
export class HomeBannerComponent {
  @Input() imageSource = '';
}
