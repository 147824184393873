import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { MatProgressBar } from '@angular/material/progress-bar';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatProgressBar,
    MatButton
],
})
export class DeleteDialogComponent {
  dialogRef = inject<MatDialogRef<DeleteDialogComponent>>(MatDialogRef);

  @Output() deleteClicked: EventEmitter<void> = new EventEmitter<void>();
  loading: boolean = false;

  onNoClick() {
    this.dialogRef.close();
  }
  onDeleteClick() {
    if (this.dialogRef) {
      this.loading = true;
      this.deleteClicked.emit();
    }
  }
}
