import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { ServiceCardComponent } from './ui/service-card/service-card.component';

const routes: Routes = [
  {
    path: '',
    title: 'Services - MyKingston ',
    loadComponent: () => import('./service-card-list.component').then((m) => m.ServiceCardListComponent),
  },
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), ServiceCardComponent],
})
export class ServiceCardModule {}
