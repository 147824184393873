import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import * as fromApp from '../../store/app.reducer';
import { selectServiceCards, selectServiceCardsLoaded } from '../data-access/store/service-card.selectors';
import { ServiceCard } from '../data-access/service-card.model';

@Injectable({ providedIn: 'root' })
export class ServiceCardService {
  private http = inject(HttpClient);
  private store = inject<Store<fromApp.AppState>>(Store);


  fetchServiceCardsAll(): Observable<ServiceCard[]> {
    return this.http.get<ServiceCard[]>(`${env.serverUrl}/services/anonymous`);
  }
  fetchServiceCardsCustomer(): Observable<ServiceCard[]> {
    return this.http.get<ServiceCard[]>(`${env.serverUrl}/customer/services`);
  }

  loaded$(): Observable<boolean> {
    return this.store.pipe(select(selectServiceCardsLoaded));
  }
  entities$(): Observable<ServiceCard[]> {
    return this.store.pipe(select(selectServiceCards));
  }
}
