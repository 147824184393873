import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-back-bar',
    templateUrl: './back-bar.component.html',
    styleUrls: ['./back-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButton, MatIcon],
})
export class BackBarComponent {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  @Input() backRoute: string = '';
  @Input() backLabel: string = 'back';

  onBack() {
    this.router.navigate([this.backRoute], { relativeTo: this.route });
  }
}
