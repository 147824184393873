<div data-testid="terms-of-service">
  <h2>Terms of Service</h2>
  <p>
    MyKingston is a central login point designed to allow you to access various services delivered by multiple
    departments of the Corporation of the City of Kingston (the “City”). Personal information, as defined by the
    Municipal Freedom of Information and Protection of Privacy Act, R.S.O. 1990, c. M.56 (“MFIPPA”), including (but not
    limited to), names, addresses, and contact information, entered into MyKingston, is collected and will be used and
    accessed by City staff, in multiple City departments, in the performance of their duties under the authority of the
    Municipal Act, 2001, S.O. 2001, c. 25, and applicable City by-laws, to deliver programs and services to you,
    including determinations of eligibility or suitability for such programs and services.
  </p>
  <p>
    Personal information will be collected and used in accordance with MFIPPA. As you access services, personal
    information collected from you may be shared with other City departments who require the information to provide you
    with the requested services. The sharing of the relevant information can improve the services offered to you and
    help you receive the right services as soon as possible.
  </p>
  <p>What you should know:</p>
  <ul>
    <li>
      Your personal information will be shared only amongst City staff who are working together to support you and
      provide services to you in the community.
    </li>
    <li>
      Your personal information will be securely stored and only City staff that need to know your information for the
      purpose of delivering services will have access to it.
    </li>
    <li>You have the right to receive a copy of all information accessed by City staff, upon request.</li>
  </ul>

  <p>
    Questions about the collection, use, and disclosure of your personal information should be directed to the
    <a href="mailto:contactus@cityofkingston.ca?subject=MyKingston Terms of Service">Contact Us</a>.
  </p>
</div>
