import { createAction, props } from '@ngrx/store';
import { CustomerEmail } from '../../customer.model';

export const setCustomerEmails = createAction(
  '[Customer Emails] Set Customer Emails',
  props<{ payload: CustomerEmail[] }>()
);

export const fetchCustomerEmails = createAction('[Customer Emails] Fetch Customer Emails');

export const addNewCustomerEmail = createAction(
  '[Customer Emails] Add New Customer Email',
  props<{ payload: CustomerEmail }>()
);

export const deleteCustomerEmail = createAction(
  '[Customer Emails] Delete Customer Emails',
  props<{ payload: string }>()
);
export const deleteCustomerEmailSuccess = createAction(
  '[Customer Emails] Delete Customer Email Success',
  props<{ payload: string }>()
);

export const updateCustomerEmailPrimary = createAction(
  '[Customer Emails] Update Customer Emails',
  props<{ payload: CustomerEmail }>()
);

export const addNewCustomerEmailSuccess = createAction(
  '[Customer Emails] Add New Customer Emails  Success',
  props<{ payload: CustomerEmail }>()
);

export const customerEmailsFailed = createAction(
  '[Customer Emails] Customer Emails Failed',
  props<{ payload: string }>()
);
export const sendCustomerEmailVerification = createAction(
  '[Customer Emails] Send email verification',
  props<{ payload: CustomerEmail }>()
);

export const updateCustomerEmailVerification = createAction(
  '[Customer Emails] Update Customer Email Verification',
  props<{ payload: CustomerEmail }>()
);
export const updateCustomerEmailVerificationSuccess = createAction(
  '[Customer Emails] Update Customer Email Verification Success',
  props<{ payload: CustomerEmail }>()
);
export const updateCustomerEmailVerificationFailed = createAction(
  '[Customer Emails] Update Customer Email Verification Failed',
  props<{ payload: CustomerEmail }>()
);

export const resendCustomerEmailVerification = createAction(
  '[Customer Emails] Resend Customer Email Verification',
  props<{ payload: CustomerEmail }>()
);
export const resendCustomerEmailVerificationSuccess = createAction(
  '[Customer Emails] Resend Customer Email Verification Success',
  props<{ payload: CustomerEmail }>()
);

export const routeCustomerToVerifyEmail = createAction('[Customer Emails] Route Customer To Verify Email');

export const clearCustomerProfileErrorMessage = createAction('[Customer Emails] Clear Customer Profile Error Message');

export const updateCustomerEmailPrimarySuccess = createAction(
  '[Customer Emails] Update Customer Email Primary Success',
  props<{ payload: CustomerEmail }>()
);
export const updateCustomerEmailPrimaryFailed = createAction(
  '[Customer Emails] Update Customer Email Primary Failed',
  props<{ payload: CustomerEmail }>()
);
