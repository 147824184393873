import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, first, Observable, switchMap, tap, throwError } from 'rxjs';
import { ServiceRequest } from './service-request.model';
import { environment as env } from '../../../../environments/environment';

import { ComponentStore } from '@ngrx/component-store';

export interface ServiceRequestState {
  requests: ServiceRequest[];
  error: string;
  loading: boolean;
}

@Injectable({ providedIn: 'root' })
export class ServiceRequestStore extends ComponentStore<ServiceRequestState> {
  private http = inject(HttpClient);

  //read state
  readonly requests$ = this.select((state) => state.requests);
  readonly error$ = this.select((state) => state.error);
  readonly loading$ = this.select((state) => state.loading);

  constructor() {
    super({ requests: null, error: '', loading: true });
  }

  fetchServiceRequest(): Observable<ServiceRequest[]> {
    return this.http.get<ServiceRequest[]>(`${env.serverUrl}/osvc/incidents`).pipe(
      catchError((err) => {
        return throwError(() => new Error(err.error.errorCode || `unknown error, ${err}`));
      })
    );
  }

  loadServiceRequests = this.effect(($) =>
    $.pipe(
      first(),
      switchMap(() => {
        return this.fetchServiceRequest().pipe(
          tap({
            next: (requests) =>
              this.patchState({
                loading: false,
                requests: requests
                  ? requests.sort((a, b) => {
                      if (a.created > b.created) return -1;
                      if (a.created < b.created) return 1;
                      if (a.created === b.created) return 0;
                    })
                  : null,
              }),
            error: (error: Error) => {
              if (error.message.startsWith('ERROR_CONTACT_NOT_FOUND')) {
                //don't show UI as an error.
                this.patchState({ loading: false, error: '', requests: null });
              } else {
                this.patchState({ error: error.message, loading: false });
              }
            },
          })
        );
      })
    )
  );
}
