<div class="footer-container">
  <div class="address">
    <span>
      &#169; {{ currentYear }} The Corporation of the City of Kingston <br />
      216 Ontario Street, Kingston, Ontario, Canada, K7L 2Z3
    </span>
  </div>
  <div class="icons">
    <span>Connect with Us</span>
    <div class="icon-container">
      <a href="https://www.facebook.com/TheCityOfKingston" target="_blank"
        ><img src="../../../assets/images/social-icons/facebook.svg" alt="Facebook icon"
      /></a>
      <a href="https://www.instagram.com/city.of.kingston/" target="_blank">
        <img src="../../../assets/images/social-icons/instagram.svg" alt="Instagram icon"
      /></a>
      <a href="https://ca.linkedin.com/company/city-of-kingston" target="_blank">
        <img src="../../../assets/images/social-icons/linkedin.svg" alt="Linkedin icon"
      /></a>
      <a href="https://www.youtube.com/TheCityofKingston" target="_blank"
        ><img src="../../../assets/images/social-icons/youtube.svg" alt="YouTube icon"
      /></a>
      <a href="https://twitter.com/cityofkingston" target="_blank">
        <img src="../../../assets/images/social-icons/twitter.svg" alt="Twitter icon"
      /></a>
    </div>
  </div>
</div>
