import { createAction, props } from '@ngrx/store';
import { CustomerFamilyMember } from '../../customer.model';

export const setCustomerFamily = createAction(
  '[Customer Family] Set Customer Family',
  props<{ payload: CustomerFamilyMember[] }>()
);

export const fetchCustomerFamily = createAction('[Customer Family] Fetch Customer Family');
export const customerFamilyFailed = createAction(
  '[Customer Family] Customer Family Failed',
  props<{ payload: string }>()
);
export const addNewCustomerFamilyMember = createAction(
  '[Customer Family] Add New Customer Family Member',
  props<{ payload: CustomerFamilyMember[] }>()
);

export const addNewCustomerFamilyMemberSuccess = createAction(
  '[Customer Family] Add New Customer Family Member Success',
  props<{ payload: CustomerFamilyMember }>()
);

export const displayCustomerFamilyDialog = createAction('[Customer Family] Display Customer Family Dialog');
export const cancelNewCustomerFamily = createAction('[Customer Family] Hide New Customer Family Dialog');
export const closeError = createAction('[Customer Family] Close Error');

export const deleteCustomerFamilyMember = createAction(
  '[Customer Family] Delete Customer Family Member',
  props<{ payload: string }>()
);

export const updateCustomerFamilyMember = createAction(
  '[Customer Family] Update Customer Family Member',
  props<{ payload: CustomerFamilyMember }>()
);
