import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { map, Observable } from 'rxjs';
import { SharedModule } from '../../shared/shared.module';
import { environment as env } from '../../../environments/environment';
import { ServiceRequest } from './data-access/service-request.model';
import { ServiceRequestStore } from './data-access/service-request.store';
import { ServiceRequestListComponent } from './ui/service-request-list/service-request-list.component';
import { select, Store } from '@ngrx/store';
import { selectAppSettings } from '../../app-settings/store/app-settings.selectors';
import { BaseWidgetSettings } from '../../app-settings/app-settings.model';
import * as fromApp from '../../store/app.reducer';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-service-requests',
  templateUrl: './service-requests.component.html',
  styleUrls: ['./service-requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, ServiceRequestListComponent, MatButtonModule],
})
export class ServiceRequestsComponent implements OnInit {
  private requestsStore = inject(ServiceRequestStore);
  private store = inject<Store<fromApp.AppState>>(Store);
  private authService = inject(AuthService);

  serviceRequests$: Observable<ServiceRequest[]>;
  loading$: Observable<boolean>;
  error$: Observable<string>;
  loggedIn$: Observable<boolean>;
  widgetSettings$: Observable<BaseWidgetSettings>;

  crmPortalLink = '';
  crmQuestionsList = `${env.osvcSSOAccountQuestionsUrl}/list`;

  ngOnInit(): void {
    //component updated to use only observable streams instead of subscribing in the component (imperative programming).
    this.loggedIn$ = this.authService.isAuthenticated$;
    this.requestsStore.loadServiceRequests();

    this.serviceRequests$ = this.requestsStore.requests$;
    this.error$ = this.requestsStore.error$;
    this.loading$ = this.requestsStore.loading$;

    this.crmPortalLink = env.osvcSSOlink;
    this.widgetSettings$ = this.store.pipe(
      select(selectAppSettings),
      map((settings) => settings.widgets.service_requests)
    );
  }
}
