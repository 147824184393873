import { Action, createReducer, on } from '@ngrx/store';
import { CustomerAddress } from '../../customer.model';
import * as CustomerAddressActions from '../actions/customer-address.actions';

export interface State {
  customerAddresses: CustomerAddress[];
  newAddress: CustomerAddress;
  isLoadingAddresses: boolean;
  addressId: string;
  isAddingNewAddress: boolean;
  addressErrorMessage: string;
  isSaving: boolean;
  loaded: boolean;
}

const initialState: State = {
  customerAddresses: [],
  isLoadingAddresses: false,
  addressId: null,
  isAddingNewAddress: false,
  addressErrorMessage: null,
  newAddress: null,
  isSaving: false,
  loaded: false,
};

const _customerAddressReducer = createReducer(
  initialState,
  on(CustomerAddressActions.fetchCustomerAddresses, (state) => ({
    ...state,
    isLoadingAddresses: true,
    isAddingNewAddress: false,
    loaded: false,
  })),
  on(CustomerAddressActions.displayCustomerAddressDialog, (state) => ({
    ...state,
    isAddingNewAddress: true,
    isSaving: false,
  })),

  on(CustomerAddressActions.customerAddressFailed, (state, action) => ({
    ...state,
    addressErrorMessage: action.payload,
    isLoadingAddresses: false,
    isSaving: false,
    loaded: false,
  })),

  on(CustomerAddressActions.startDeleteCustomerAddress, (state, action) => ({
    ...state,
    addressId: action.payload,
    isSaving: true,
  })),

  on(CustomerAddressActions.deleteCustomerAddressSuccess, (state) => ({
    ...state,
    addressId: null,
    isAddingNewAddress: false,
    isSaving: false,
    addressErrorMessage: '',
    customerAddresses: state.customerAddresses.filter((_address, index, customerAddresses) => {
      return customerAddresses[index].addressId !== state.addressId;
    }),
  })),

  on(CustomerAddressActions.startUpdateCustomerAddress, (state) => ({
    ...state,
    isSaving: true,
  })),

  on(CustomerAddressActions.updateCustomerAddressSuccess, (state, action) => {
    let indexToUpdate = state.customerAddresses.findIndex((x) => x.addressId === action.payload.addressId);

    const updatedAddresses = state.customerAddresses.map((address, index) => {
      if (index !== indexToUpdate) {
        return address;
      }
      return {
        ...address,
        ...action.payload,
      };
    });

    return {
      ...state,
      customerAddresses: [...updatedAddresses],
      isAddingNewAddress: false,
      addressErrorMessage: '',
      isSaving: false,
    };
  }),
  on(CustomerAddressActions.startAddNewCustomerAddress, (state, action) => ({
    ...state,
    newAddress: action.payload,
    isSaving: true,
    loaded: true,
  })),
  on(CustomerAddressActions.addCustomerAddressSuccess, (state, action) => ({
    ...state,
    customerAddresses: [...state.customerAddresses, action.payload],
    isAddingNewAddress: false,
    isSaving: false,
    addressErrorMessage: '',
  })),
  on(CustomerAddressActions.setCustomerAddresses, (state, action) => ({
    ...state,
    customerAddresses: [...action.payload],
    isLoadingAddresses: false,
    loaded: true,
  })),
  on(CustomerAddressActions.closeError, (state) => ({
    ...state,
    addressErrorMessage: null,
  }))
);
export function customerAddressReducer(state: State, action: Action) {
  return _customerAddressReducer(state, action);
}
