import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { GarbageServices } from '../../data-access/garbage-collection.model';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-garbage-services-list',
  templateUrl: './garbage-services-list.component.html',
  styleUrls: ['./garbage-services-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, MatIconModule, MatButtonModule],
})
export class GarbageServicesListComponent {
  @Input() garbageServices: GarbageServices;
}
