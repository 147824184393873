import { createReducer, on, Action } from '@ngrx/store';
import { ServiceCard } from '../service-card.model';
import * as ServiceCardActions from './service-card.actions';
import * as CustomerActions from '../../../customer/store/actions/customer.actions';

export interface State {
  serviceCards: ServiceCard[];
  loaded: boolean; //have we already loaded a list of services
  loading: boolean;
  errorMessage: string | null;
  favorite: boolean;
  favServices: string[];
}

const initialState: State = {
  serviceCards: [],
  loaded: false,
  loading: false,
  errorMessage: null,
  favorite: true,
  favServices: [],
};

const _serviceCardReducer = createReducer(
  initialState,
  on(ServiceCardActions.ServiceCardsLoaded, (state, action) => {
    const copyServices = action.serviceCards.map((card) => ({ ...card }));
    if (state.favServices?.length > 0) {
      copyServices.map((service) => {
        service.isFavorite = state.favServices.includes(service.serviceId);
      });
    }

    return { ...state, serviceCards: copyServices, loaded: true, loading: false, errorMessage: null };
  }),
  on(ServiceCardActions.fetchAllServiceCards, ServiceCardActions.fetchServiceCardsByCustomer, (state) => ({
    ...state,
    loading: true,
  })),

  on(ServiceCardActions.ServiceCardsFailure, (state, action) => ({
    ...state,
    loaded: false,
    loading: false,
    errorMessage: action.errorMessage,
  })),

  on(
    CustomerActions.setCustomerPreferences,
    CustomerActions.setCustomerPreferencesFromApi,
    CustomerActions.saveCustomerPreferences,

    (state, action) => {
      const copyServices = state.serviceCards.map((card) => ({ ...card }));

      if (copyServices && action.payload.favServices) {
        copyServices.map((service) => {
          service.isFavorite = action.payload.favServices.includes(service.serviceId);
        });
      }
      return {
        ...state,
        favServices: action.payload.favServices,
        serviceCards: copyServices,
      };
    }
  )
);

export function serviceCardReducer(state: State, action: Action) {
  return _serviceCardReducer(state, action);
}
