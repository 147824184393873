import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ContentShellComponent } from './content-shell/content-shell.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
    imports: [SharedModule, ContentShellComponent, FooterComponent, HeaderComponent],
    exports: [ContentShellComponent, FooterComponent, HeaderComponent],
})
export class LayoutModule {}
