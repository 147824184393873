import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';

import { CustomerAddress } from '../../customer/customer.model';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { TitleCasePipe } from '@angular/common';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';

@Component({
    selector: 'app-address-manual-entry',
    templateUrl: './address-manual-entry.component.html',
    styleUrls: ['./address-manual-entry.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    NgxMaskDirective,
    MatRadioGroup,
    MatRadioButton,
    TitleCasePipe
],
})
export class AddressManualEntryComponent {
  private cdr = inject(ChangeDetectorRef);

  @Input() address: CustomerAddress;
  @Input() addressTypes = new Set();

  postalMask = 'S0S 0S0';
  postalPlaceholder = 'Ex. A0A 0A0';

  countryChange(event: string) {
    this.setPostalAttributes(event);
  }
  setPostalAttributes(country: string) {
    if (country?.toLowerCase() === 'canada' || country?.toLowerCase() === 'ca') {
      this.postalMask = 'S0S 0S0';
      this.postalPlaceholder = 'Ex. A0A 0A0';
      this.address.postalCode = '';
    } else {
      this.postalMask = null;
      this.postalPlaceholder = null;
    }
    this.cdr.detectChanges();
  }
}
