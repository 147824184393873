import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { MatButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'app-ui-email-verify',
    templateUrl: './ui-email-verify.component.html',
    styleUrls: ['./ui-email-verify.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatButton,
    MatProgressBar
],
})
export class UiEmailVerifyComponent {
  @Input() backNav = true;
  @Input() instructions_label = 'Verify your email address by entering the 6 digit code emailed to you.';
  @Input() loading = false;
  @Input() error = '';
  @Output() verifyCode: EventEmitter<string> = new EventEmitter();
  @Output() resendCode: EventEmitter<boolean> = new EventEmitter();

  verificationCode: string;

  onVerify() {
    this.verifyCode.emit(this.verificationCode);
  }
  onResend() {
    this.resendCode.emit();
  }
}
