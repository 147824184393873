import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { environment, environment as env } from './environments/environment';
import { Router } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
  HttpClientJsonpModule,
} from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { AuthInterceptor } from './app/auth/auth.interceptor';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { SharedModule } from './app/shared/shared.module';
import { LayoutModule } from './app/layout/layout.module';
import { HomeModule } from './app/home/home.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './app/auth/store/auth.effects';
import { CustomerEffects } from './app/customer/store/effects/customer.effects';
import { MfapEffects } from './app/services/mfap/store/mfap.effects';
import { ServiceCardEffects } from './app/service-cards/data-access/store/service-card.effects';
import { AppSettingsEffects } from './app/app-settings/store/app-settings.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { InitModule } from './app/utilities/init.module';
import { AppComponent } from './app/app.component';
import * as fromApp from './app/store/app.reducer';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.description,
  release: environment.version,
  integrations: [Sentry.browserTracingIntegration()],

  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && environment.test) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }

    const error = hint.originalException as Error;
    console.log(error);
    if (error && error && error.message && error.message.includes('ERROR_CONTACT_NOT_FOUND')) return null;
    return event;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.sentry.tracesSampleRate, //portion of transactions to be sampled
});

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      SharedModule,
      LayoutModule,
      HomeModule,
      HttpClientJsonpModule,
      StoreModule.forRoot(fromApp.appReducer),
      EffectsModule.forRoot([AuthEffects, CustomerEffects, MfapEffects, ServiceCardEffects, AppSettingsEffects]),
      StoreDevtoolsModule.instrument({ logOnly: env.production }),
      StoreRouterConnectingModule.forRoot(),
      AuthModule.forRoot({
        //this seems to stop the redirect and allows the state param to stay
        skipRedirectCallback: window.location.pathname === '/error',
        useRefreshTokensFallback: true,
        ...env.auth,
        //attach the auth0 token to every request to the myk_api
        httpInterceptor: {
          allowedList: [
            `${env.serverUrl}/settings*`,
            `${env.serverUrl}/onboarding*`,
            `${env.serverUrl}/customer*`,
            `${env.serverUrl}/customer`,
            // `${env.serverUrl}/services*`,
            `${env.serverUrl}/mfap/*`,
            `${env.serverUrl}/osvc/*`,
            `${env.serverUrl}/waste/*`,
            `${env.serverUrl}/address/*`,
          ],
        },
      }),
      InitModule
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
})
  //.then((module) => enableDebugTools(module.injector.get(ApplicationRef).components[0]))
  .catch((err) => console.error(err));
