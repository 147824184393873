import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { Observable } from 'rxjs';
import { SharedModule } from '../../../../shared/shared.module';
import { FirePermit } from '../../data-access/fire-permit.model';

@Component({
  selector: 'app-fire-permit-list',
  templateUrl: './fire-permit-list.component.html',
  styleUrls: ['./fire-permit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, MatDialogModule, MatButtonModule, MatDividerModule],
})
export class FirePermitListComponent {
  data: { permits: Observable<FirePermit[]> } = inject(MAT_DIALOG_DATA);
}
