import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WssConnectionService } from '../../utilities/wss-connection.service';
import { AppSettingsService } from '../app-settings.service';
import * as AppSettingsActions from './app-settings.actions';

@Injectable()
export class AppSettingsEffects {
  private actions$ = inject(Actions);
  private auth0Service = inject(AuthService);
  private wssConnectionService = inject(WssConnectionService);
  private appSettingsService = inject(AppSettingsService);

  appSettingsConnect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppSettingsActions.wsApiConnect),
        switchMap(() => {
          console.log('getting access token silently in app-settings');
          return this.auth0Service.getAccessTokenSilently({ authorizationParams: { cacheMode: 'off' } });
        }),
        switchMap((token) => {
          // console.log('connecting with token:', token);
          return this.wssConnectionService.connect$(token);
        }),
        map((message) => this.wssConnectionService.processMessage(message)),
        catchError((errorRes) => {
          console.log('could not connect to app settings:', errorRes);
          return of(AppSettingsActions.appSettingsFailed({ error: errorRes }));
        })
      );
    },
    { dispatch: false }
  );

  fetchApplicationSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppSettingsActions.fetchApplicationSettings),
      switchMap(() => {
        return this.appSettingsService.fetchApplicationSettings().pipe(
          map((settings) => {
            if (!settings) {
              return { type: 'DUMMY STATE - CUSTOMER PROFILE NOT FOUND' };
            } else {
              return AppSettingsActions.setApplicationSettings({ settings });
            }
          }),
          catchError((errorRes) => {
            console.log('app settings error', errorRes);
            return of(AppSettingsActions.appSettingsFailed({ error: errorRes }));
          })
        );
      })
    );
  });
}
