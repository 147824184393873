import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/customer-family.reducer';
// get the `customerFamily` property from the state object
export const getCustomerFamilyFeatureState = createFeatureSelector<State>('customerFamily');

export const selectCustomerFamily = createSelector(
  getCustomerFamilyFeatureState,
  (state: State) => state.customerFamily
);
export const selectCustomerFamilyErrorMessage = createSelector(
  getCustomerFamilyFeatureState,
  (state: State) => state.familyErrorMessage
);
export const selectCustomerFamilyLoading = createSelector(
  getCustomerFamilyFeatureState,
  (state: State) => state.loading
);
export const selectCustomerFamilyDialogOpen = createSelector(
  getCustomerFamilyFeatureState,
  (state: State) => state.isAddingNewMember
);
export const selectCustomerFamilyTemp = createSelector(
  getCustomerFamilyFeatureState,
  (state: State) => state.customerFamilyTemp
);
export const selectIsSaving = createSelector(getCustomerFamilyFeatureState, (state: State) => state.isSaving);
export const selectCustomerFamilyHasPartner = createSelector(getCustomerFamilyFeatureState, (state: State) =>
  state.customerFamily.find((member) => member.roleType == 'partner')
);

export const selectCustomerFamilyLoaded = createSelector(getCustomerFamilyFeatureState, (state: State) => state.loaded);
