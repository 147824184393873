import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from './snackbar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private _snackBar = inject(MatSnackBar);

  openSnackBar(message: string, action = 'Dismiss') {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: 5000,
      data: { message, action },
    });
  }
}
