import { inject, NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { AccountCompleteGuard } from './auth/account-complete.guard';

import { LogoutGuard } from './auth/logout/logout.guard';

import { AuthGuard } from '@auth0/auth0-angular';

import { environment as env } from '../environments/environment';
const routes: Routes = [
  {
    path: 'error',
    loadChildren: () => import('./account-error/account-error.module').then((m) => m.AccountErrorModule),
  },
  {
    path: 'logout',
    loadComponent: () => import('./auth/logout/logout.component').then((m) => m.LogoutComponent),
    canActivate: [LogoutGuard],
    title: 'Logout - MyKingston',
  },

  {
    path: 'services',
    loadChildren: () => import('./service-cards/service-card.module').then((m) => m.ServiceCardModule),
  },
  {
    path: 'terms-of-service',
    loadComponent: () =>
      import('./shared/terms-of-service/terms-of-service.component').then((m) => m.TermsOfServiceComponent),
    title: 'Terms - MyKingston',
  },
  {
    path: 'home',
    title: 'Home - MyKingston',
    loadComponent: () => import('./home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'address',
    loadChildren: () =>
      import('./widgets/residential-property-info/residential-property.module').then(
        (m) => m.ResidentialPropertyModule
      ),
  },
  {
    path: 'register',
    canActivate: [AccountCompleteGuard],
    loadChildren: () => import('./account-register/account-register.module').then((m) => m.AccountRegisterModule),
  },
  {
    path: 'subscriptions',
    canActivate: [
      AuthGuard,
      () => {
        const router = inject(Router);
        return env.description !== 'production' ? true : router.createUrlTree(['/home']);
      },
    ],
    loadComponent: () =>
      import(
        './customer/customer-subscriptions/customer-email-subscription/customer-email-subscription.component'
      ).then((m) => m.CustomerEmailSubscriptionComponent),
  },
  {
    path: '',
    canActivateChild: [AccountCompleteGuard],
    children: [
      {
        path: 'profile',

        loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerModule),
      },
      {
        path: 'mfap',
        loadChildren: () => import('./services/mfap/mfap.module').then((m) => m.MfapModule),
      },
      {
        path: '',
        loadComponent: () => import('./home/home.component').then((m) => m.HomeComponent),
        title: 'Home - MyKingston',
      },

      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
