import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ServiceRequest } from '../../data-access/service-request.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-service-request-list',
  templateUrl: './service-request-list.component.html',
  styleUrls: ['./service-request-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxSkeletonLoaderModule, MatDividerModule, DatePipe],
})
export class ServiceRequestListComponent {
  @Input() requests: ServiceRequest[] = [];
  @Input() loading = false;

  // Getter to access only the first request in the array
  get requestToDisplay(): ServiceRequest[] | [] {
    return [this.requests?.[0]] ?? [];
  }
}
