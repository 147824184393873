import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { catchError, filter, Observable, of, switchMap, timer } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { environment as env } from '../../environments/environment';
import * as AppSettingsActions from '../app-settings/store/app-settings.actions';
import { MfapApplication } from '../services/mfap/mfap.model';
import * as MfapActions from '../services/mfap/store/mfap.actions';

@Injectable({
  providedIn: 'root',
})
export class WssConnectionService {
  private store = inject(Store);

  connection$: WebSocketSubject<any>;

  connect$(token: string): Observable<any> {
    // console.log('starting WS connection to url:', `${env.serverWebSocketUrl}?access_token=${token}`);
    return of(`${env.serverWebSocketUrl}?access_token=${token}`).pipe(
      filter((apiUrl) => !!apiUrl),
      switchMap((wsUrl) => {
        if (this.connection$) {
          return this.connection$;
        } else {
          this.connection$ = webSocket({
            url: wsUrl,
            closeObserver: {
              next: () => {
                console.log('wss connection close - retrying');
                timer(3000);
                this.store.dispatch(AppSettingsActions.wsApiConnect());
              },
            },
            openObserver: {
              next: () => {
                console.log('connectionOpened');
                this.store.dispatch(AppSettingsActions.fetchApplicationSettings());
              },
            },
          });
          return this.connection$;
        }
      }),
      catchError(() => of(this.store.dispatch(AppSettingsActions.wsApiConnect())))
      // retry({
      //   count: 20,
      //   delay: (err, attempt) =>
      //     of(err, attempt).pipe(
      //       tap(() => console.log('websocket connection error:', err, attempt)),
      //       switchMap(() => timer(3000))
      //     ),
      // })
    );
  }

  processMessage(message: { eventName: string; eventItem: string; message: string }) {
    console.log('received message:', message);
    if (message.eventName === 'globalSettings') {
      this.store.dispatch(AppSettingsActions.fetchApplicationSettings());
    }

    switch (message.eventItem) {
      case 'mfap': {
        if (message.eventName === 'update' || message.eventName === 'create') {
          const mfap: MfapApplication = JSON.parse(message.message);
          this.store.dispatch(MfapActions.setMfapApplication({ payload: mfap }));
        }
        if (message.eventName === 'delete') {
          this.store.dispatch(MfapActions.deleteMfapApplicationSuccess());
        }

        break;
      }

      default:
        console.log('could not process event item', message.eventItem);

        break;
    }
  }
}
