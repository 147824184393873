import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ServiceCard } from '../../../../service-cards/data-access/service-card.model';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatCardActions } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { TitleCasePipe } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RouterLink } from '@angular/router';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-feature-item',
    templateUrl: './feature-item.component.html',
    styleUrls: ['./feature-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatIcon,
    MatCardContent,
    NgxSkeletonLoaderModule,
    RouterLink,
    MatCardActions,
    MatButton,
    TitleCasePipe
],
})
export class FeatureItemComponent {
  @Input() featureName = { title: '', iconName: '' };
  @Input() services: ServiceCard[];
  @Input() loading = false;
}
