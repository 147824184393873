import { createAction, props } from '@ngrx/store';

export const checkAuth = createAction('[Auth] Check Auth');
export const login = createAction('[Auth] Login');
export const createAccount = createAction('[Auth] Create Account');
export const loginComplete = createAction(
  '[Auth] Login Complete',
  props<{ profile: any; isLoggedIn: boolean; token: string }>()
);

export const logout = createAction('[Auth] Confirm Logout');
export const logoutComplete = createAction('[Auth] Logout Complete');
export const sendPasswordChangeEmail = createAction('[Auth] Send Password Change Email');
export const passwordChangeEmailSent = createAction('[Auth] Password Change Email Sent');

export const authFailed = createAction('[Auth] Auth Action Failed', props<{ payload: string }>());

export const clearPasswordChangeMessage = createAction('[Auth] Clear Password Change Email Message');
export const extendLogoutTimer = createAction('[Auth] Extend Logout Timer');
export const inactivityDetected = createAction('[Auth] Inactivity Detected');
