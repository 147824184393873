import { Action, createReducer, on } from '@ngrx/store';
import { Customer, CustomerPreferences } from '../../customer.model';
import * as CustomerActions from '../actions/customer.actions';

export interface State {
  customer: Customer;
  isLoadingCustomer: boolean;
  isUpdatingCustomer: boolean;
  errorMessage: string;
  isSaving: boolean;
  showCustomerPersonalDialog: boolean;
  preferences: CustomerPreferences;
  preferencesLoaded: boolean;
}

const initialState: State = {
  customer: null,
  isLoadingCustomer: false,
  isUpdatingCustomer: false,
  errorMessage: null,
  isSaving: false,
  showCustomerPersonalDialog: false,
  preferences: { darkMode: false, favServices: [], shownTutorial: false },
  preferencesLoaded: false,
};

const _customerReducer = createReducer(
  initialState,
  on(CustomerActions.setCustomer, (state, action) => ({
    ...state,
    customer: action.payload,
    isAddingNewAddress: false,
    isLoadingCustomer: false,
  })),

  on(CustomerActions.setCustomerPreferences, (state, action) => ({
    ...state,
    preferences: { ...state.preferences, ...action.payload },
  })),
  on(CustomerActions.setCustomerPreferencesFromApi, (state, action) => ({
    ...state,
    preferences: { ...state.preferences, ...action.payload },
    preferencesLoaded: true,
  })),
  on(CustomerActions.saveCustomerPreferences, (state, action) => ({
    ...state,
    preferences: { ...state.preferences, ...action.payload },
    //preferencesLoaded: false,
  })),
  on(CustomerActions.patchCustomerPreferences, (state, action) => {
    let newPreferences = { ...state.preferences, ...action.payload };
    console.log('new preferences', newPreferences);
    return {
      ...state,
      preferences: newPreferences,
      //preferencesLoaded: false,
    };
  }),

  on(CustomerActions.displayCustomerPersonalDialog, (state) => ({
    ...state,
    isUpdatingCustomer: true,
    isSaving: false,
  })),

  on(CustomerActions.hideCustomerPersonalDialog, (state) => ({
    ...state,
    errorMessage: null,
    isUpdatingCustomer: false,
    errMessage: null,
  })),

  on(CustomerActions.customerFailed, (state, action) => ({
    ...state,
    errorMessage: action.payload,
    isLoadingCustomer: false,
    isSaving: false,
  })),

  on(CustomerActions.closeError, (state) => ({
    ...state,
    errorMessage: null,
  })),
  on(CustomerActions.startUpdateCustomer, (state) => ({
    ...state,

    isSaving: true,
  })),
  on(CustomerActions.updateCustomerSuccess, (state, action) => ({
    ...state,
    customer: action.payload,
    isUpdatingCustomer: false,
    isSaving: false,
  }))

  //dependents
);
export function customerReducer(state: State, action: Action) {
  return _customerReducer(state, action);
}
