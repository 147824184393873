@if (index > 0) {
  <mat-divider style="margin-top: 0.5rem"></mat-divider>
}
<div class="item">
  <div class="label">
    {{ label | titlecase }}
  </div>
  <div data-testid="details" class="details">
    <ng-content></ng-content>
  </div>
</div>
<div class="action-row">
  @if (buttons.edit.visible) {
    <button
      mat-stroked-button
      (click)="onEdit()"
      [attr.data-testid]="'edit-' + getDataCyName(label)"
      >
      {{ buttons.edit.label }}
    </button>
  }
  <!-- <span *ngIf="buttons.edit.visible && buttons.remove.visible">|</span> -->
  @if (buttons.remove.visible) {
    <button
      mat-button
      (click)="onRemove()"
      [attr.data-testid]="'delete-' + getDataCyName(label)"
      >
      {{ buttons.remove.label }}
    </button>
  }
</div>
