import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerAddress } from '../../customer/customer.model';
import { GoogleMapsService } from '../../utilities/google-maps.service';
import { AsyncPipe } from '@angular/common';
import { AddressSearchInputComponent } from './address-search-input/address-search-input.component';

@Component({
    selector: 'app-address-search',
    templateUrl: './address-search.component.html',
    styleUrls: ['./address-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    AddressSearchInputComponent,
    AsyncPipe
],
})
export class AddressSearchComponent {
  private googleMapsService = inject(GoogleMapsService);

  @Input() address: CustomerAddress;
  @Output() addressChange = new EventEmitter<CustomerAddress>();

  mapsApiLoaded$: Observable<boolean>;

  constructor() {
    this.mapsApiLoaded$ = this.googleMapsService.getApiLoaded$();
  }

  onChange(address: CustomerAddress) {
    console.log('address search shell emitting', address);
    this.addressChange.emit(address);
  }
}
