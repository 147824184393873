import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './service-card.reducer';

export const getServiceCardFeatureState = createFeatureSelector<State>('serviceCards');

export const selectServiceCards = createSelector(getServiceCardFeatureState, (state: State) => state.serviceCards);
export const selectServiceCardsLoaded = createSelector(getServiceCardFeatureState, (state: State) => state.loaded);
export const selectServiceCardsLoading = createSelector(getServiceCardFeatureState, (state: State) => state.loading);
export const selectServiceCardsErrorMessage = createSelector(
  getServiceCardFeatureState,
  (state: State) => state.errorMessage
);
