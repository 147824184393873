import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-consent-to-use-info',
    templateUrl: './consent-to-use-info.component.html',
    styleUrls: ['./consent-to-use-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ConsentToUseInfoComponent {}
