import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if (req.url.includes(`${env.serverUrl}`)) {
      //only if the request is for the myKingston api
      const authReq = req.clone({
        setHeaders: {
          //Authorization: 'COKAUTH',
          'x-api-key': env.auth.x_api_key,
          // Authorization:
          //   'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InpYMUR0Mks1MlRpRVpMRXI3Qk4tQyJ9.eyJpc3MiOiJodHRwczovL2Rldi0xbjg1dTZzMi51cy5hdXRoMC5jb20vIiwic3ViIjoicWN5a2ZJQUpLU2JhNlBPZmVqR3p5T3Z1ZUVWekJHSU5AY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vYXV0aDAtand0LWF1dGhvcml6ZXIiLCJpYXQiOjE2MTU5MjA5MTAsImV4cCI6MTYxNjAwNzMxMCwiYXpwIjoicWN5a2ZJQUpLU2JhNlBPZmVqR3p5T3Z1ZUVWekJHSU4iLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMifQ.oQUZgIUFyxunya1ARmqzYJkulCBMVZispf9sTnKf7GN1x_vanQDx2W8bhf_HQjdeNE_t1MAKld6Naux9G-WJ4K71BGGUmRGkdhUJmAVB-RVZ48_f_yHckeyquc-K6ChGwtTjkNin4ta2B8jC7GUfWQAOHmEoRAKjy54iRHqNN28N-qrLWc10gjY5uWEysyx6xnxBe8_sw54bQ9bjq97WDx0xtBhO-7r87-IF-W0wubhk-Wi9lbXJkLPsFZELKg0n5JdH1yaRKGFFYl0xGCgWGSO-4hfxxYNqUI94snBoZGSTMNY0AuCn3RiHig0IBAfvR6oKSzx9JyZlgtZar472ng',
        },
      });
      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }
}
