<h2>Consent to use and disclose personal information</h2>
<h3>Pursuant to the Municipal Freedom of Information and Protection of Privacy Act.</h3>
<p>
  I understand that by clicking on the “I accept” button below, I am providing written consent and authorization for the
  purposes of the Municipal Freedom of Information and Protection of Privacy Act, R.S.O. 1990, c. M.56, for the
  Corporation of the City of Kingston to use and disclose my personal information already in its custody or under its
  control, being personal information previously collected by the City when delivering services or programs to me, for
  the purpose of delivering future programs and services to me, including determining eligibility or suitability for
  such programs and services, through MyKingston.
</p>

<p>
  I understand that the personal information that I provided to the City previously will be shared and accessed by City
  staff who are currently providing services to me.
</p>
