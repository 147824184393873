<div class="features">
  @for (item of featureItems; track item) {
    <div>
      <app-feature-item
        [loading]="loading$ | async"
        [featureName]="item"
        [services]="(item.services$ | async).slice(0, maxItems)"
      ></app-feature-item>
    </div>
  }
</div>
