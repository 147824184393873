import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppInitService } from './app-init.service';
import * as Sentry from '@sentry/angular';

function initLoader(myInitService: AppInitService) {
  return () => myInitService.loadPreferences();
}

@NgModule({
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initLoader,
      deps: [AppInitService, Sentry.TraceService],
      multi: true,
    },
  ],
})
export class InitModule {}
