import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { CustomerAddress } from '../../../customer/customer.model';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap, catchError } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { MessageDisplayComponent } from '../../message-display/message-display.component';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-address-search-input',
  templateUrl: './address-search-input.component.html',
  styleUrls: ['./address-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MessageDisplayComponent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatAutocomplete,
    MatOption,
    MatIcon,
    MatDivider,
    MatError,
    MatSuffix,
    AsyncPipe
],
})
export class AddressSearchInputComponent implements AfterViewInit {
  private cdr = inject(ChangeDetectorRef);

  @Input() address: CustomerAddress;
  @Output() addressSelected = new EventEmitter<CustomerAddress>();
  hasStreetNumber = true;

  addressControl = new FormControl();
  filteredOptions: Observable<google.maps.places.AutocompletePrediction[]>;

  center = { lat: 44.22988110146884, lng: -76.48044577956765 };
  // Create a bounding box with sides ~10km away from the center point
  //https://developers.google.com/maps/documentation/javascript/places-autocomplete#set-options-at-construction
  defaultBounds = {
    north: this.center.lat + 0.1,
    south: this.center.lat - 0.1,
    east: this.center.lng + 0.1,
    west: this.center.lng - 0.1,
  };

  options = {
    types: ['premise', 'street_address', 'subpremise'],
    componentRestrictions: { country: ['ca', 'us'] },
    bounds: this.defaultBounds,
    strictBounds: false,
  };

  @ViewChild('searchField') searchField: ElementRef;
  @ViewChild('map', { static: false }) mapElement: ElementRef;

  ngAfterViewInit(): void {
    this.filteredOptions = this.addressControl.valueChanges.pipe(
      debounceTime(300),
      switchMap((value) => {
        if (value && value.length > 0) {
          return new Observable<google.maps.places.AutocompletePrediction[]>((observer) => {
            const service = new google.maps.places.AutocompleteService();
            service.getPlacePredictions({ input: value, ...this.options }, (predictions, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                observer.next(predictions);
              } else {
                observer.next([]);
              }
              observer.complete();
            });
          });
        } else {
          return of([]);
        }
      }),
      catchError(() => of([]))
    );
  }

  onOptionSelected(event: any) {
    const selectedOption: google.maps.places.AutocompletePrediction = event.option.value;
    const placeId = selectedOption.place_id;

    this.addressControl.setValue(selectedOption.description);

    const placesService = new google.maps.places.PlacesService(this.mapElement.nativeElement);
    placesService.getDetails(
      { placeId: placeId, fields: ['address_components', 'geometry', 'place_id'] },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          this.handleAddressChange(place);
        }
      }
    );
  }

  handleAddressChange(place: google.maps.places.PlaceResult) {
    let address1 = '';
    let postCode = '';
    let hasLocality = false;

    this.address = {
      ...this.address,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      goPlaceId: place.place_id,
      city: '',
      provinceOrTerritory: '',
      country: '',
    };

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case 'route': {
          address1 += component.short_name;
          break;
        }

        case 'postal_code': {
          postCode = `${component.long_name}${postCode}`;
          break;
        }

        case 'postal_code_suffix': {
          postCode = `${postCode}-${component.long_name}`;
          break;
        }

        case 'locality':
          this.address = { ...this.address, city: component.long_name };
          hasLocality = true;
          break;

        case 'administrative_area_level_1': {
          this.address = { ...this.address, provinceOrTerritory: component.long_name };
          break;
        }
        case 'administrative_area_level_3': {
          if (!hasLocality) {
            this.address = { ...this.address, city: component.long_name };
          }
          break;
        }
        case 'administrative_area_level_2': {
          this.address = { ...this.address, region: component.long_name };
          break;
        }
        case 'country':
          this.address = { ...this.address, country: component.long_name };
          break;
      }
    }

    this.address = {
      ...this.address,
      streetAddress: address1,
      postalCode: postCode,
      unit: '',
    };
    console.log('selected address', this.address);
    if (address1 !== '') {
      this.addressSelected.emit(this.address);
    } else {
      this.hasStreetNumber = false;
      this.cdr.detectChanges();
    }
  }
}
