import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-message-display',
    templateUrl: './message-display.component.html',
    styleUrls: ['./message-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    NgClass,
    MatIcon,
    MatIconButton
],
})
export class MessageDisplayComponent {
  @Input() message = '';
  @Input() showCloseButton = true;
  @Input() messageType: 'info' | 'error' | 'success' = 'error';

  @Output() closeMessage = new EventEmitter<boolean>();

  onCloseError() {
    this.closeMessage.emit(true);
  }
  public get classes(): string[] {
    return [`message-${this.messageType}`, 'message'];
  }
}
