import { Action, createReducer, on } from '@ngrx/store';
import { AppSettings } from '../app-settings.model';
import * as AppSettingsActions from './app-settings.actions';

export interface AppSettingsState {
  settings: AppSettings;
  error: string;
}

export const initialState: AppSettingsState = {
  settings: {
    mfap: {
      ehb_isEnabled: false,
      pv_isEnabled: false,
      spark_isEnabled: false,
    },
    widgets: {
      district: { visible: true, requires_permissions: [] },
      parking_permits: { visible: true, requires_permissions: [] },
      service_requests: { visible: true, requires_permissions: [] },
      tax: { visible: true, myTax_link_visible: true, requires_permissions: [] },
      waste: { visible: true, requires_permissions: [] },
      building_permits: { visible: true, requires_permissions: [] },
      fire_permit: { visible: true, requires_permissions: [] },
    },
  },
  error: '',
};

const appSettingsReducerInternal = createReducer(
  initialState,

  on(AppSettingsActions.setApplicationSettings, (state, { settings }) => {
    return {
      ...state,
      settings: settings,
      error: '',
    };
  }),
  on(AppSettingsActions.appSettingsFailed, (state, { error }) => {
    return {
      ...state,
      error: error,
    };
  })
);

export function appSettingsReducer(state: AppSettingsState | undefined, action: Action): AppSettingsState {
  return appSettingsReducerInternal(state, action);
}
