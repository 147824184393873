import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/customer-email.reducer';

// get the `customerEmail` property from the state object
export const getCustomerEmailFeatureState = createFeatureSelector<State>('customerEmail');

// get the current customer from the customer state test
export const selectCustomerEmails = createSelector(getCustomerEmailFeatureState, (state: State) => state.emails);

export const selectWaitingToVerifyEmail = createSelector(
  getCustomerEmailFeatureState,
  (state: State) => state.waitingToVerify
);

export const selectEmailToVerifyId = createSelector(
  getCustomerEmailFeatureState,
  (state: State) => state.emailToVerifyId
);

export const selectEmailSuccessfullyVerified = createSelector(
  getCustomerEmailFeatureState,
  (state: State) => state.emailVerifiedSuccess
);
export const selectEmailError = createSelector(getCustomerEmailFeatureState, (state: State) => state.emailError);
export const selectEmailLoading = createSelector(getCustomerEmailFeatureState, (state: State) => state.loading);

export const selectPrimaryEmailUpdated = createSelector(
  getCustomerEmailFeatureState,
  (state: State) => state.primaryEmailUpdated
);
export const selectCustomerEmailsLoaded = createSelector(getCustomerEmailFeatureState, (state: State) => state.loaded);
