@if (garbageServices) {
  <ul>
    @for (service of garbageServices; track service) {
      <li>
        <div class="service-item">
          @if (service.type === 'greybox' || service.type === 'bluebox') {
            <span>
              <mat-icon [style.color]="service.type === 'greybox' ? '#999999' : '#008c9e'">recycling</mat-icon>
            </span>
          }
          @if (service.type === 'greenbin') {
            <span>
              <mat-icon class="greenbin">compost</mat-icon>
            </span>
          }
          @if (service.type === 'garbage' || service.type === 'twogarbage') {
            <span>
              <mat-icon>delete</mat-icon>
            </span>
          }
          @if (service.type === 'Battery_Collection') {
            <span>
              <mat-icon>battery_charging_full</mat-icon>
            </span>
          }
          @if (service.type === 'leafweek') {
            <span class="leafweek">
              <mat-icon>spa</mat-icon>
            </span>
          }
          @if (service.type === 'brushweek') {
            <span>
              <mat-icon>park</mat-icon>
            </span>
          }
          <div class="service-label">
            {{ service.label }}
          </div>
        </div>
      </li>
    }
  </ul>
}
