import { createAction, props } from '@ngrx/store';
import { Customer, CustomerPreferences } from '../../customer.model';

export const fetchCustomer = createAction('[Customer] Fetch Customer Profile');

export const setCustomer = createAction('[Customer] Set Customer', props<{ payload: Customer }>());

export const displayCustomerPersonalDialog = createAction('[Customer] Display Customer Personal Dialog');
export const hideCustomerPersonalDialog = createAction('[Customer] Hide Customer Personal Dialog');

export const startUpdateCustomer = createAction('[Customer] Update Customer START', props<{ payload: Customer }>());
export const updateCustomerSuccess = createAction('[Customer] Update Customer SUCCESS', props<{ payload: Customer }>());

export const customerFailed = createAction('[Customer] Customer Failed', props<{ payload: string }>());

export const closeError = createAction('[Customer] Close Customer Error');

export const setCustomerPreferences = createAction(
  '[Customer] Set Customer Preferences',
  props<{ payload: CustomerPreferences }>()
);
export const setCustomerPreferencesFromApi = createAction(
  '[Customer] Set Customer Preferences from API',
  props<{ payload: CustomerPreferences }>()
);

export const setFavouriteServiceCards = createAction(
  '[Customer] Set Favourite Service Cards',
  props<{ payload: string[] }>()
);

export const saveCustomerPreferences = createAction(
  '[Customer] Save Customer Preferences',
  props<{ payload: CustomerPreferences }>()
);
export const patchCustomerPreferences = createAction(
  '[Customer] Patch Customer Preferences',
  props<{ payload: CustomerPreferences }>()
);
export const closeCustomerDialog = createAction('[Customer] Close Customer Dialogs');
