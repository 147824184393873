import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.reducer';

// get the `auth` property from the state object
export const getAuthFeatureState = createFeatureSelector<AuthState>('auth');

// get the userProfile from the auth state
export const selectCurrentUserProfile = createSelector(getAuthFeatureState, (state: AuthState) => state.userProfile);

// get the isLoggedIn from the auth state
export const selectIsLoggedIn = createSelector(getAuthFeatureState, (state: AuthState) => state.isLoggedIn);
export const selectIsCOKUser = createSelector(getAuthFeatureState, (state: AuthState) => {
  const userEmail: string = state.userProfile?.email || '';
  return userEmail.endsWith('@cityofkingston.ca');
});

export const selectIsSendingPasswordChangeEmail = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.sendingPasswordChangeEmail
);

export const selectPasswordChangeEmailSent = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.passwordChangeEmailSent
);

export const selectLoginEmail = createSelector(getAuthFeatureState, (state: AuthState) => {
  return state.userProfile?.email;
});
export const selectCheckingAuthState = createSelector(getAuthFeatureState, (state: AuthState) => {
  return state.checkingAuthState;
});

export const selectAuthPermissions = createSelector(getAuthFeatureState, (state: AuthState) => {
  return state.permissions;
});
