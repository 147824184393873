import { Action, createReducer, on } from '@ngrx/store';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as AuthActions from '../../auth/store/auth.actions';

export interface AuthState {
  isLoggedIn: boolean;
  userProfile: any;
  checkingAuthState: boolean;
  sendingPasswordChangeEmail: boolean;
  passwordChangeEmailSent: boolean;
  permissions: Permissions;
}

export const initialState: AuthState = {
  isLoggedIn: false,
  userProfile: null,
  checkingAuthState: true,
  sendingPasswordChangeEmail: false,
  passwordChangeEmailSent: false,
  permissions: { create: [], read: [], update: [], delete: [] },
};

interface PermissionsKeys {
  [key: string]: string[];
}
export interface Permissions extends PermissionsKeys {
  create: string[];
  read: string[];
  update: string[];
  delete: string[];
}

const authReducerInternal = createReducer(
  initialState,

  on(AuthActions.checkAuth, (state, {}) => {
    return { ...state, checkingAuthState: true };
  }),
  on(AuthActions.authFailed, (state, {}) => {
    return { ...state, checkingAuthState: false };
  }),

  on(AuthActions.loginComplete, (state, { profile, isLoggedIn, token }) => {
    //let permissionsResolved = false;
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const permissions: Permissions = {
      create: [],
      read: [],
      update: [],
      delete: [],
    };
    if (decodedToken.permissions) {
      //permissionsResolved = true;
      for (const entry of decodedToken.permissions) {
        const [permissionType, permissionName]: string = entry.split(':');
        permissions[permissionType].push(permissionName);
      }
    }
    return {
      ...state,
      userProfile: profile,
      isLoggedIn,
      permissions,
      checkingAuthState: false,
    };
  }),
  on(AuthActions.logoutComplete, (state, {}) => {
    return {
      ...state,
      userProfile: null,
      isLoggedIn: false,
      checkingAuthState: false,
    };
  }),
  on(AuthActions.sendPasswordChangeEmail, (state, {}) => {
    return {
      ...state,
      passwordChangeEmailSent: false,
      sendingPasswordChangeEmail: true,
    };
  }),
  on(AuthActions.passwordChangeEmailSent, (state, {}) => {
    return {
      ...state,
      passwordChangeEmailSent: true,
      sendingPasswordChangeEmail: false,
    };
  }),

  on(AuthActions.clearPasswordChangeMessage, (state, {}) => {
    return {
      ...state,
      passwordChangeEmailSent: false,
      sendingPasswordChangeEmail: false,
    };
  })
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return authReducerInternal(state, action);
}
