import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { AppSettings } from '../app-settings/app-settings.model';
import { Permissions } from './store/auth.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private authService = inject(AuthService);
  private http = inject(HttpClient);

  userEmail: string = null;

  get isLoggedIn$(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  get accessToken$(): Observable<string> {
    return this.authService.getAccessTokenSilently({});
  }

  getToken$(): Observable<string> {
    return this.authService.getAccessTokenWithPopup({
      authorizationParams: {
        audience: 'appsync-auth0',
      },
    });
  }

  getUserSilently(): Observable<any> {
    return this.authService.user$.pipe(
      switchMap((user) => {
        //console.log('fist switchmap');
        if (!user) {
          // console.log('no user found' + user);
          //there is no logged in user, try to get token silently
          return this.authService.getAccessTokenSilently().pipe(
            map((token) => {
              // console.log('trying to get token');
              //could not get the token
              if (!token) return of(false);
              //return the user token
              return of(token);
            }),
            catchError((err) => {
              console.log(err);
              return of(false);
            })
          );
        }
        //there is a logged in user, return the user
        // console.log('user found with authService.user$ ');
        // console.log(user);
        return of(user);
      })
    );
  }

  get user$(): Observable<any> {
    return this.authService.user$;
    //could create a similar method. get customerProfile. Would be a get call to the api gateway /customer endpoint
  }

  login(): void {
    const targetPath = window.location.pathname;
    const redirectPath = targetPath !== '/logout' ? targetPath : '/';
    this.authService.loginWithRedirect({ appState: { target: redirectPath } });
  }
  createAccount(): void {
    this.authService.loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } });
  }

  signup() {
    this.authService.loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } });
  }

  logout(): void {
    this.http
      .post(`${env.serverUrl}/customer/logout`, {})
      .pipe(
        take(1),
        catchError((error) => {
          console.log('error logging out', error);
          return of(null);
        })
      )

      .subscribe((value) => {
        console.log('logout response', value);
        this.authService.logout({ logoutParams: { returnTo: `${env.home}/logout` } });
      });
  }
  sendPasswordChangeEmail(): Observable<any> {
    return this.authService.user$.pipe(
      take(1),
      switchMap((userValue) => {
        return this.http.post(`${env.serverUrl}/customer/notify`, {
          customerId: userValue['http://mykingston/customerId'],
          action: 'reset-password',
        });
      })
    );
  }

  canAccess$(settings: AppSettings, permissions: Permissions): Observable<AppSettings> {
    const newSettings = JSON.parse(JSON.stringify(settings));

    for (const widget in settings.widgets) {
      if (settings.widgets[widget]['requires_permissions'].length > 0) {
        for (const value of settings.widgets[widget]['requires_permissions']) {
          const [permissionType, permissionName]: string = value.split(':');

          if (!permissions[permissionType].includes(permissionName)) {
            newSettings.widgets[widget].visible = false;
          }
        }
      }
    }

    return of(newSettings);
  }
}
