import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { CustomerAddress } from '../../../../customer/customer.model';
import { TitleCasePipe } from '@angular/common';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'app-customer-address-detail',
    templateUrl: './customer-address-detail.component.html',
    styleUrls: ['./customer-address-detail.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [
    MatRadioGroup,
    FormsModule,
    MatRadioButton,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    TitleCasePipe
],
})
export class CustomerAddressDetailComponent {
  @Input() address: CustomerAddress;
  @Input() addressTypes: Set<string>;
  @Output() unitNumberSelectionEmitter = new EventEmitter<string>();
  @Output() unitNumber = new EventEmitter<boolean>();
  unitNumberSelection: string;

  onUnitNumberSelectionChange(selectedValue: string) {
    this.unitNumberSelectionEmitter.emit(selectedValue);
  }
  onUnitNumberChange() {
    this.unitNumber.emit(true);
  }
}
