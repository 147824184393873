import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CustomerAddressComponent } from '../../customer/customer-address-list/customer-address/customer-address.component';
import * as CustomerAddressActions from '../../customer/store/actions/customer-address.actions';
import * as fromApp from '../../store/app.reducer';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-add-residential-address',
    templateUrl: './add-residential-address.component.html',
    styleUrls: ['./add-residential-address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButton],
})
export class AddResidentialAddressComponent {
  private dialog = inject(MatDialog);
  private store = inject<Store<fromApp.AppState>>(Store);


  onAddAddress() {
    this.store.dispatch(CustomerAddressActions.displayCustomerAddressDialog());
    this.dialog.open(CustomerAddressComponent, {
      data: { mode: 'new', filter: 'residential' },
      maxWidth: 400,
    });
  }
}
