<div [ngClass]="classes">
  <div class="text">
    @if (messageType === 'info') {
      <mat-icon class="icon">info</mat-icon>
    }
    @if (messageType === 'success') {
      <mat-icon class="icon">check_circle</mat-icon>
    }
    @if (messageType === 'error') {
      <mat-icon class="icon">warning</mat-icon>
    }
    <p style="margin: 0">
      {{ message }}
      <ng-content></ng-content>
    </p>
  </div>
  @if (showCloseButton) {
    <button
      mat-icon-button
      (click)="onCloseError()"
      data-testid="close-error"
      aria-label="Close message"
      >
      <mat-icon>highlight_off</mat-icon>
    </button>
  }
</div>
